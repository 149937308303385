<template>
  <div>

    <!-- new table starts here-->

    <div class="tableMainWrapBox" style="margin-top: 25px; position: relative" id="preview-record">
      <div class="tableScrollWrap" ref="newOrdertable">
        <table>
          <thead>
            <tr>
              <th>
                <div class="ctmCheckWrap">
                  <label class="ctmTableCheckContainer">
                    <input 
                      type="checkbox"
                      @click="toggleIsCheckedAll()"
                      :checked="
                        checkedItems.length != 0 &&
                        checkedItems.length == orders.length
                      "
                      :disabled="tourIsActive || this.selectedOrder.id != ''"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="ctmTableImgWrap">
                    <p data-toggle="tooltip" data-placement="top" title="Products">Products</p>
                  </div>
                </div>
              </th>
              <th class="ctmPr-3">
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Order No.">Order No.</p>
                </div>
              </th>
              <th class="ctmPr-3">
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                </div>
              </th>
              <th class="ctmPr-3">
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Email">Email</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Date/Time">Date/Time</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Total">Total</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Payment">Payment</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Status">Status</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Shipping Address">Shipping Address</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="boxisLoading">
            <tr v-for="i in 5" :key="i">
              <td>
                <div class="ctmCheckWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="17"
                    :height="20"
                    animation="fade"
                  />
                  <div class="orderTableProducts">
                    <vue-skeleton-loader
                      type="rect"
                      :width="100"
                      :height="20"
                      animation="fade"
                    />
                  </div>
                </div>
              </td>
              <td class="mainTableTitle">
                <div class="orderIdTxtWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="80"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="110"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="newStatusTag">
                  <vue-skeleton-loader
                    type="rect"
                    :width="150"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="180"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="ctmDataTableActionBtn">
                  <vue-skeleton-loader
                    type="rect"
                    :width="50"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-if="orders.length == 0">
              <td colspan="10" style="text-align: center">
                <p class="mb-0">No Orders Available</p>
              </td>
            </tr>
            <tr v-for="(order, index) in orders" :key="index">

              <!-- checkbox and images block starts here-->
              <td @click.prevent="previewOrder(order, index,'input',$event)" >
                <div class="ctmCheckWrap" data-open="true">
                  <label class="ctmTableCheckContainer" data-open="false" @click="checkedItemHandle(order)">
                    <input 
                      type="checkbox"
                      v-model="checkedItems"
                      :value="order.id"
                      data-open="false"
                    />
                    <span class="checkmark" data-open="false"></span>
                  </label>
                  <div class="orderTableProducts" data-open="true">
                    <div class="ctmDataTableImgBox" v-for="(item, i) in order.items.slice(0,3)" :key="'item' + i" data-open="true">
                      <p v-if="i >= 3" data-open="true">+{{ order.items.length - 3 }}</p>
                      <img :src=" item.image ? base_image_url + settings.store_id + '/product/' + item.image : '/images/no_image.png'" alt="" data-open="true" v-else/>
                    </div>
                    <div class="ctmDataTableImgBox" v-if="order.items.length > 3" data-open="true">
                      <p data-open="true">+{{ order.items.length - 3 }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <!-- checkbox and images block ends here-->

              <!-- order no. block starts here-->
              <td class="mainTableTitle" @click.prevent="previewOrder(order, index,'input',$event)" data-open="true">
                <div class="orderIdTxtWrap" data-open="true">
                  <p data-open="true">#{{ order.order_no }}</p>
                  <button @click.prevent="copyOrderNo(order)" data-open="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.236 10.642">
                      <g id="copy" transform="translate(0.05 0.05)">
                        <path id="Path_127" data-name="Path 127" d="M5.625,14.516h.7v.7h5.8v-7.2h-.7v-.7h1.406v8.609h-7.2V14.516Z" transform="translate(-3.693 -5.38)" fill="#5ab734" stroke="#5ab734" stroke-width="0.1"/>
                        <path id="Path_128" data-name="Path 128" d="M19.4,1.125V9.558H12.375V4.142l3.017-3.017Zm-6.325,7.73H18.7V1.828H16.416V5.166H13.077Zm0-4.392h2.635V1.828h-.03L13.078,4.433Z" transform="translate(-12.375 -1.125)" fill="#5ab734" stroke="#5ab734" stroke-width="0.1"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </td>
              <!-- order no. block ends here-->

              <!-- customer name block starts here-->
              <td @click.prevent="previewOrder(order, index,'solid',$event)">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p data-open="true">
                    {{ order.customer.first_name + " " + order.customer.last_name }}
                  </p>
                </div>
              </td>
              <!-- customer name block ends here-->

              <!-- customer email block starts here-->
              <td @click.prevent="previewOrder(order, index,'solid',$event)">
                <div class="tableBodyCtmTxt">
                  <p>{{ order.customer.email }}</p>
                </div>
              </td>
              <!-- customer email block ends here-->

              <!-- date block starts here-->
              <td style="position: relative" @click.prevent="previewOrder(order, index,'solid',$event)" data-open="true">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p style="font-size: 12px" data-open="true">
                    {{
                      order.created_at
                        ? new Date(order.created_at).toLocaleString("en-US", {
                            year: "numeric",
                          }) +
                          "-" +
                          new Date(order.created_at).toLocaleString("en-US", {
                            month: "numeric",
                          }) +
                          "-" +
                          new Date(order.created_at).toLocaleString("en-US", {
                            day: "numeric",
                          }) +
                          " | " +
                          new Date(order.created_at).toLocaleTimeString("en-US")
                        : "--"
                      }}
                  </p>
                </div>
              </td>
              <!-- date block ends here-->

              <!-- total block starts here-->
              <td style="position: relative" @click.prevent="previewOrder(order, index,'solid',$event)" data-open="true">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p data-open="true">
                    <b data-open="true">
                      {{
                        order.payment.net_amount ? settings.currency_unit ? settings.currency_symbol + " " +parseFloat(order.payment.net_amount).toLocaleString( "ur-PK",
                            {
                              
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0
                        : settings.currency_symbol + " " + parseFloat(order.payment.net_amount).toLocaleString( "ur-PK",
                            {
                              
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                      }}
                    </b>
                  </p>
                </div>
              </td>
              <!-- total block ends here-->

              <!-- payment method block starts here-->
              <td style="position: relative" @click.prevent="previewOrder(order, index,'solid',$event)" data-open="true">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p data-open="true">{{ order.payment.payment_method.type }}</p>
                </div>
              </td>
              <!-- payment method block ends here-->

              <!-- status block starts here-->
              <td style="position: relative" @click.prevent="previewOrder(order, index,'input',$event)" data-open="true">
                <div class="statusTableColumn" data-open="true">
                  <select 
                    name="status" 
                    id="status" 
                    :class="order.status" 
                    v-model="order.status" 
                    @mouseenter="orderSelection(order)" 
                    @change="handleOrderStatusChange(order, index)"
                    :disabled="tourIsActive || (order.id != -1 && (order.status == 'Delivered' || order.status == 'Cancelled' || order.status == 'Deleted' || order.status == 'Refunded'))"
                    data-open="false">
                    <option value="Paid" :class="order.status == 'Paid' ? 'Paid' : ''" data-open="false">Paid</option>
                    <option value="Pending" :class="order.status == 'Pending' ? 'Pending' : ''" data-open="false">Pending</option>
                    <option value="Confirmed" :class="order.status == 'Confirmed' ? 'Confirmed' : ''" data-open="false">Confirmed</option>
                    <option value="Dispatched" :class="order.status == 'Dispatched' ? 'Dispatched' : ''" data-open="false">Dispatched</option>
                    <option value="Delivered" :class="order.status == 'Delivered' ? 'Delivered' : ''" data-open="false">Delivered</option>
                    <option value="Cancelled" :class="order.status == 'Cancelled' ? 'Cancelled' : ''" data-open="false">Cancelled</option>
                    <option value="Refunded" :class="order.status == 'Refunded' ? 'Refunded' : ''" data-open="false">Refunded</option>
                    <option value="Deleted" :class="order.status == 'Deleted' ? 'Cancelled' : ''" data-open="false" v-if="order.status == 'Deleted'">Deleted</option>
                  </select>
                </div>
              </td>
              <!-- status block ends here-->

              <!-- shipping detail block starts here-->
              <td @click.prevent="previewOrder(order, index,'solid',$event)">
                <div class="tableBodyCtmTxt" v-if="order.order_details" data-open="true">
                  <p data-open="true">{{ order.order_details.shipping_address ? order.order_details.shipping_address.length > 25 ? order.order_details.shipping_address.substr(0, 25) + "..." : order.order_details.shipping_address : '--' }}</p>
                </div>
                <div class="tableBodyCtmTxt" v-else>
                  <p data-open="true">{{ order.customer.address_line_1 ?  order.customer.address_line_1.length > 25 ?  order.customer.address_line_1.substr(0, 25) + "..." : order.customer.address_line_1 : '--' }}</p>
                </div>
              </td>
              <!-- shipping detail block ends here-->

              <!-- action block starts here-->
              <td @click.prevent="previewOrder(order, index,'input',$event)" data-open="true">
                <div class="ctmDataTableActionBtn" v-if="isDeletingItem(order.id) && deleteLoader" data-open="true">
                  <loaderBtn/>
                </div>
                <div class="ctmDataTableActionBtn" data-open="true" v-else>
                  <button class="delFunction" @click.prevent="deleteOrder(order, index)" data-open="false" v-if="order.status != 'Deleted'" :disabled="tourIsActive">
                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                  </button>
                </div>
              </td>
              <!-- action block ends here-->

            </tr>
            <!-- load more block starts here -->
            <tr v-if="isPending">
              <td>
                <div class="ctmCheckWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="17"
                    :height="20"
                    animation="fade"
                  />
                  <div class="orderTableProducts">
                    <vue-skeleton-loader
                      type="rect"
                      :width="100"
                      :height="20"
                      animation="fade"
                    />
                  </div>
                </div>
              </td>
              <td class="mainTableTitle">
                <div class="orderIdTxtWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="80"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="110"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="newStatusTag">
                  <vue-skeleton-loader
                    type="rect"
                    :width="150"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="180"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="ctmDataTableActionBtn">
                  <vue-skeleton-loader
                    type="rect"
                    :width="50"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
            </tr>
            <!-- load more block ends here -->
          </tbody>
        </table>
      </div>

      <!-- order add/update form starts here-->
      <ValidationObserver ref="orderTableObserver">
        <div class="productSlideBox" ref="viewInputForm" :class="selectedOrder.id && selectedOrder.id != '' ? 'activeRow' : ''">
          <div class="tableActionBtns" id="order-details-preview">
            <div class="productTableSideBar">
              <div class="row">
                <div class="col-md-6">
                  <div class="viewOrderTitle">
                    <p>{{ selectedOrder.customer.first_name + " " + selectedOrder.customer.last_name}}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="orderViewNav">
                    <ul>
                      <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                        <li class="sm-extand-hide">
                          <button @click.prevent="expand" :disabled="tourIsActive">
                            <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="expand-form">
                              <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                <path d="m16.5 5.5v-4.978l-5.5.014" />
                                <path d="m16.5.522-6 5.907" />
                                <path d="m11 16.521 5.5.002-.013-5.5" />
                                <path d="m16.5 16.429-6-5.907" />
                                <path d="m.5 5.5v-5h5.5" />
                                <path d="m6.5 6.429-6-5.907" />
                                <path d="m6 16.516-5.5.007v-5.023" />
                                <path d="m6.5 10.5-6 6" />
                              </g>
                            </svg>
                          </button>
                        </li>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                        <li>
                          <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                              <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                  <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                  <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </li>
                      </el-tooltip>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="sectionTabsLink">
              <ul onclick="window.myFunction(event)">
                <li><a href="#customerDetail" id="customer-details-navbtn" v-show="!tourIsActive">Customer Details</a></li>
                <li><a href="#orderActivities" id="order-activity-navbtn" v-show="!tourIsActive">Order Activity</a></li>
                <li><a href="#orderDetailsSection" id="order-details-navbtn" v-show="!tourIsActive">Order Details</a></li>
              </ul>
              <div class="updateBtnBox">
                <loaderBtn v-if="orderLoader"/>
                <button 
                  @click.prevent="validate('update')" 
                  v-else-if="selectedOrder.id != -1" 
                  :disabled="(tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')) || tourIsActive"
                >
                  Update
                </button>
                <button @click.prevent="validate('add')" id="save-order-btn" :disabled="tourIsActive" v-else>Save</button>
              </div>
            </div>
          </div>
          <div class="productTableInfoBlock">

            <!-- Product Block -->
            <div class="productTableInfoWhiteBox" id="customerDetail">
              <div class="orderAdminTitle" v-if="selectedOrder.created_by">
                <p>
                  Created by: <span>{{ selectedOrder.created_by }}</span>
                </p>
              </div>
              <div class="addOrderTable" id="product-section">
                <table>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in selectedOrder.items" :key="index">
                      <td>
                        <div class="productOrderTableImg">
                          <img :src="item.image ?  base_image_url + settings.store_id + '/product/' + item.image : '/images/no_image.png'"/>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>{{ item.name.length > 15 ? item.name.substr(0,15) + "..." : item.name }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTagTxt">
                          <p>{{ item.type }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>
                            <!-- {{ 
                            
                              item.variant.current_price ? item.variant.price == item.variant.current_price ? 
                              settings.currency_symbol + " " + item.variant.price.toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) : 
                              settings.currency_symbol + " " + item.variant.current_price.toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) :
                              settings.currency_symbol + " " + item.variant.price.toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            }} -->
                            {{
                              settings.currency_symbol + " " + item.variant.price.toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="quantityOrderProductBox">
                          <button
                            class="miniusIconBtn"
                            @click.prevent="quantityChange('decr',index)"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          >
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <g id="_-" data-name=" -" class="cls-1">
                                  <g class="cls-1">
                                    <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                          <input
                            type="number"
                            name="stock"
                            id="stock"
                            placeholder="0"
                            v-model="item.variant.qty"
                            @blur="quantityChangeCheck(index)"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <button
                            class="plusIconBtn"
                            @click.prevent="quantityChange('incr',index)"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          >
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <g id="_" data-name=" " class="cls-1">
                                  <g class="cls-1">
                                    <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>
                            {{
                              calculateProductTotal(item) ? settings.currency_symbol + " " + calculateProductTotal(item).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) : ''
                            }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="ctmDataTableActionBtn">
                          <button
                            class="delFunction"
                            @click.prevent="removeItem(item, 'sliderForm', index)"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          >
                            <i aria-hidden="true" class="fa fa-trash-o"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <span class="text-danger" v-show="(!selectedOrder.customer.id && selectedOrder.items.length == 0)">Product(s) must be added.</span>
              <div class="orderAddProductBtn">
                <button 
                  @click.prevent="OpenProductModal"
                  :disabled="(tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')) || tourIsActive"
                  id="add-product-btn"
                >
                  + Add Product
                </button>
              </div>
            </div>
            <!-- Customer Block -->
            <div class="productTableInfoWhiteBox" id="customerSection">
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Customer Details</h5>
              </div>
              <form id="customer-section">
                <div class="tableFromFieldBox" @click="productSelectionMessage" id="add-customer-dropdown">
                  <label for="cName">Customer Name<span>*</span></label>
                    <div class="vueSelectBlock">
                      <Multiselect
                        ref="customerMultiSelect"
                        v-model="selectedOrder.customer"
                        :options="list_customer"
                        placeholder="Select one"
                        label="first_name"
                        track-by="id"
                        :open-direction="'bottom'"
                        :allow-empty="false"
                        deselect-label="Can't remove the selected item"
                        :custom-label="customerCustomLabel"
                        @close="onTouchCustomer"
                        @select="handleSelected($event)"
                        :disabled="
                          (selectedOrder.created_by &&
                          selectedOrder.created_by == 'customer') || tempOrder.id != -1 && (
                          tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)
                        "
                      >
                        <span slot="noResult">
                          No customer found.
                        </span>
                        <li class="multiselect__element" slot="afterList" @click.prevent="openCustomerForm">
                        <span class="multiselect__option">Add New Customer</span>
                        </li>
                      </Multiselect>
                      <span class="text-danger" v-if="customerFieldRequired">The customer field is required</span>
                    </div>
                </div>
                <div class="customerDetailList" id="customer-basic-details">
                  <ul>
                    <li>
                      <h6>Name:</h6>
                      <p>
                        {{
                          selectedOrder.customer.first_name +
                          " " +
                          selectedOrder.customer.last_name
                        }}
                      </p>
                    </li>
                    <li>
                      <h6>Email:</h6>
                      <p>{{ selectedOrder.customer.email }}</p>
                    </li>
                    <li>
                      <h6>Contact Number:</h6>
                      <p>{{ selectedOrder.customer.mobile_no }}</p>
                    </li>
                    <li>
                      <h6>Address:</h6>
                      <p>
                        {{selectedOrder.customer.address_line_2 != null ?
                          selectedOrder.customer.address_line_1 +
                          " " +
                          selectedOrder.customer.address_line_2 :
                          selectedOrder.customer.address_line_1
                        }}
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="productTableInfoWhiteBoxTitle">
                  <h5>Shipping Details</h5>
                </div>
                <div class="row" id="customer-shipping-details">
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping first name"
                      :rules="{required:true,max:50}"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cSFName">First Name <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="cSFName"
                            id="cSFName"
                            placeholder="First Name"
                            v-model="selectedOrder.shipping.shipping_first_name"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                            @input="onChangeInField('first_name')"
                            />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping last name"
                      :rules="{required:true,max:50}"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cSLName">Last Name <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="cSLName"
                            id="cSLName"
                            placeholder="Last Name"
                            v-model="selectedOrder.shipping.shipping_last_name"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                            @input="onChangeInField('last_name')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping email"
                      rules="required|email|max:250"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cSEmail">Email <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="cSEmail"
                            id="cSEmail"
                            placeholder="Email"
                            v-model="selectedOrder.shipping.shipping_email"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                            @input="onChangeInField('email')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping contact no."
                      :rules="shippingCountryIsSelected ? 'required' : '' "
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox" id="shipping-contact">
                        <label for="cSCNumber">Contact No. <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <vue-tel-input 
                            v-model="selectedOrder.shipping.shipping_contact_no"
                            mode="international"
                            :onlyCountries="allowedCountries"
                            :inputOptions="ContactFieldOptions"
                            ref="shippingContactNoField"
                            :dropdownOptions="ContactFieldDropDownOptions"
                            :validCharactersOnly=true 
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                            @input="shippingContactInputHandle"
                            @validate="shippingValidateContactNo"
                            @country-changed="shippingCountryChangeHandle"
                            @focus="shippingContactFieldFocusHandle"
                            @close="shippingDropDownCloseHandle"
                          >
                          </vue-tel-input>
                          <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                          <span class="text-danger" v-else-if="!shippingCountryIsSelected && shippingContactFieldTouched">Select a country from the dropdown menu or add the country code before the contact no.</span>
                          <span class="text-danger" v-else-if="!shippingContactIsValid && shippingContactFieldTouched">Enter a valid mobile no.</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping country"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cSCountry">Country<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedCountry"
                            id="cSCountry"
                            :options="countriesList"
                            placeholder="Select Country"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                          >
                          <span slot="noResult">No country found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping state"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cSState">State<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedFormState"
                            id="cSState"
                            :options="listStates"
                            placeholder="Select State"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                          >
                          <span slot="noOptions">A country must be selected</span>
                          <span slot="noResult">No state found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping city"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cSCity">City<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedCity"
                            id="cSCity"
                            :options="listCities"
                            placeholder="Select City"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                          >
                          <span slot="noOptions">A state must be selected</span>
                          <span slot="noResult">No city found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12" @click="productSelectionMessage">
                    <ValidationProvider
                      name="shipping address"
                      rules="required|max:500"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cSAddress">Shipping Address<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="cSAddress"
                            id="cSAddress"
                            placeholder="Shipping Address"
                            v-model="shipping_address"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0)"
                            @input="onChangeInField('address')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- Billing Details -->
                <div class="productTableInfoWhiteBoxTitle">
                  <div class="row align-items-center">
                    <div class="col-md-6" >
                      <h5>Billing Details</h5>
                    </div>
                    <div class="col-md-6">
                      <div class="ctmShippingLink" id="same-billing-checkbox">
                        <label> 
                          Same as Shipping
                          <input type="checkbox" v-model="isSameBilling" @change="onChangeSameBilling" :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded' || tourIsActive">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" id="customer-billing-details">
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="billing first name"
                      :rules="{required:true,max:50}"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cBFName">First Name <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="cBFName"
                            id="cBFName"
                            placeholder="First Name"
                            v-model="selectedOrder.billing.billing_first_name"
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="billing last name"
                      :rules="{required:true,max:50}"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cBLName">Last Name <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="cBLName"
                            id="cBLName"
                            placeholder="Last Name"
                            v-model="selectedOrder.billing.billing_last_name"
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="billing email"
                      rules="required|email|max:250"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cBEmail">Email <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="cBEmail"
                            id="cBEmail"
                            placeholder="Email"
                            v-model="selectedOrder.billing.billing_email"
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage" id="billing-contact">
                    <ValidationProvider
                      name="billing contact no."
                      :rules="billingCountryIsSelected ? 'required' : ''"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cBCNumber">Contact No. <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <vue-tel-input 
                            v-model="selectedOrder.billing.billing_contact_no"
                            mode="international"
                            :onlyCountries="allowedCountries"
                            :inputOptions="ContactFieldOptions"
                            ref="billingContactNoField"
                            :dropdownOptions="ContactFieldDropDownOptions"
                            :validCharactersOnly=true 
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                            @input="billingContactInputHandle"
                            @validate="billingValidateContactNo"
                            @country-changed="billingCountryChangeHandle"
                            @focus="billingContactFieldFocusHandle"
                            @close="billingDropDownCloseHandle"
                          >
                          </vue-tel-input>
                          <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                          <span class="text-danger" v-else-if="!billingCountryIsSelected && billingContactFieldTouched">Select a country from the dropdown menu or add the country code before the contact no.</span>
                          <span class="text-danger" v-else-if="!billingContactIsValid && billingContactFieldTouched">Enter a valid mobile no.</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="billing country"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cBCountry">Country<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingCountry"
                            id="cBCountry"
                            :options="countriesList"
                            placeholder="Select Country"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                          >
                          <span slot="noResult">No country found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="billing state"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cBState">State<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingState"
                            id="cBState"
                            :options="listBillingStates"
                            placeholder="Select State"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                          >
                          <span slot="noOptions">A country must be selected</span>
                          <span slot="noResult">No state found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click="productSelectionMessage">
                    <ValidationProvider
                      name="billing city"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="cBCity">City<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingCity"
                            id="cBCity"
                            :options="listBillingCities"
                            placeholder="Select City"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                          >
                          <span slot="noOptions">A state must be selected</span>
                          <span slot="noResult">No city found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12" @click="productSelectionMessage" id="orderActivities">
                    <ValidationProvider
                      name="billing address"
                      rules="required|max:500"
                      v-slot="{ errors }"
                    >
                      <div class="tableFromFieldBox">
                        <label for="sBAddress">Billing Address<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="sBAddress"
                            id="sBAddress"
                            placeholder="Shipping Address"
                            v-model="billing_address"
                            :disabled="isSameBilling || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (selectedOrder.items.length == 0))"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </div>
            <!-- Order Activity Block -->
            <div class="productTableInfoWhiteBox" id="orderActivitySection">
              <div class="productTableInfoWhiteBoxTitle" v-if="selectedOrder.id != -1">
                <h5>Order Activity</h5>
              </div>
              <div class="activityList">
                <div class="orderActivityList" v-if="selectedOrder.id != -1 && selectedOrder.activities.length == 0">
                  <ul>
                    <li>
                      <h6>No Activity Logs Available</h6>
                    </li>
                  </ul>
                </div>
                <div class="orderActivityList" v-else>
                  <ul>
                    <li v-for="(activity, index) in selectedOrder.activities" :key="index">
                      <h6>{{ activity.activity }}</h6>
                      <p>
                        on
                        {{
                          activity.created_at ? new Date(activity.created_at).toLocaleString("en-UK",
                                {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }
                              )
                            : "Not Available"
                        }}
                        at
                        {{ activity.created_at ? new Date(activity.created_at).toLocaleTimeString( "en-US"): "Not Available" }}
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="customerOrderNoteBox" id="customer-order-note">
                  <h5>
                    Customer Order Notes
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.75 13.75">
                      <path 
                        id="exclamationsign"
                        d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"
                        opacity="0.63"
                      />
                    </svg>
                  </h5>
                  <p v-if="selectedOrder.order_notes">
                    {{ selectedOrder.order_notes }}
                  </p>
                  <p class="noOrderNotes" v-else>No order notes available</p>
                </div>
                <div class="addOrderNoteBox" id="admin-order-notes">
                  <h5>Admin Order Notes</h5>
                  <ul v-if=" selectedOrder.notes.length == 0 && tempOrderNotes.length == 0">
                    <li>No admin notes available</li>
                  </ul>
                  <ul v-else>
                    <li>
                      <div class="row" v-for="(note, index) in selectedOrder.notes" :key="note.id + index">
                        <div class="col-md-10">
                          <p class="noteListTxt">
                            {{ note.note }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <div class="actionIconWrap">
                            <button 
                              @click.prevent="orderNoteModalPreview(note, index)"
                              :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.547 15.417">
                                <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(0.65 0.65)">
                                  <path id="Path_52165" data-name="Path 52165" d="M9.314,6H4.4A1.4,1.4,0,0,0,3,7.4v9.822a1.4,1.4,0,0,0,1.4,1.4h9.822a1.4,1.4,0,0,0,1.4-1.4V12.314" transform="translate(-3 -4.512)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                  <path id="Path_52166" data-name="Path 52166" d="M19.367,3.254a1.488,1.488,0,1,1,2.1,2.1l-6.665,6.665-2.806.7.7-2.806Z" transform="translate(-7.791 -2.818)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                </g>
                              </svg>
                            </button>
                            <button 
                            @click.prevent="removeOrderNote(note, index)" 
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 12.233 14.016">
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect id="Rectangle_20937" data-name="Rectangle 20937" width="12.233" height="14.016" fill="#fd4d5d"/>
                                  </clipPath>
                                </defs>
                                <g id="Group_24764" data-name="Group 24764" transform="translate(0 0)">
                                  <g id="Group_23700" data-name="Group 23700" transform="translate(0 0)" clip-path="url(#clip-path)">
                                    <path id="Path_51546" data-name="Path 51546" d="M1.019,7.044H.54a.51.51,0,1,1,0-1.018H11.675a.511.511,0,1,1,0,1.018h-.463v.2q0,4.563,0,9.126A2.088,2.088,0,0,1,9.381,18.5a1.806,1.806,0,0,1-.27.011q-2.994,0-5.989,0a2.081,2.081,0,0,1-2.1-2.1q0-4.579,0-9.158Zm9.176.009H2.038v.164q0,4.554,0,9.108a1.085,1.085,0,0,0,1.174,1.169H9.024a1.086,1.086,0,0,0,1.17-1.172q0-4.546,0-9.092Z" transform="translate(0 -4.497)" fill="#fd4d5d"/>
                                    <path id="Path_51547" data-name="Path 51547" d="M16.609,0H18.6a.511.511,0,1,1,0,1.018H14.619A.509.509,0,0,1,14.062.5a.508.508,0,0,1,.556-.5h1.991" transform="translate(-10.494 0)" fill="#fd4d5d"/>
                                    <path id="Path_51548" data-name="Path 51548" d="M16.084,22.14q0,1.25,0,2.5a.511.511,0,1,1-1.018,0q0-2.5,0-5a.511.511,0,1,1,1.018,0q0,1.25,0,2.5" transform="translate(-11.243 -14.24)" fill="#fd4d5d"/>
                                    <path id="Path_51549" data-name="Path 51549" d="M30.146,22.156q0,1.242,0,2.485a.511.511,0,1,1-1.018,0q0-2.5,0-5a.511.511,0,1,1,1.018,0q0,1.258,0,2.516" transform="translate(-21.736 -14.24)" fill="#fd4d5d"/>
                                  </g>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="tempOrderNotes.length > 0">
                      <h5 class="newNoteTxt">New Notes</h5>
                      <div class="row" v-for="(note, index) in tempOrderNotes" :key="index">
                        <div class="col-md-10">
                          <p class="noteListTxt">
                            {{ note.note }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <div class="actionIconWrap">
                            <button 
                              @click.prevent="orderNoteModalPreview(note, index)"
                              :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.547 15.417">
                                <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(0.65 0.65)">
                                  <path id="Path_52165" data-name="Path 52165" d="M9.314,6H4.4A1.4,1.4,0,0,0,3,7.4v9.822a1.4,1.4,0,0,0,1.4,1.4h9.822a1.4,1.4,0,0,0,1.4-1.4V12.314" transform="translate(-3 -4.512)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                  <path id="Path_52166" data-name="Path 52166" d="M19.367,3.254a1.488,1.488,0,1,1,2.1,2.1l-6.665,6.665-2.806.7.7-2.806Z" transform="translate(-7.791 -2.818)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                </g>
                              </svg>
                            </button>
                            <button 
                              @click.prevent="removeOrderNote(note, index)"
                              :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 12.233 14.016">
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect id="Rectangle_20937" data-name="Rectangle 20937" width="12.233" height="14.016" fill="#fd4d5d"/>
                                  </clipPath>
                                </defs>
                                <g id="Group_24764" data-name="Group 24764" transform="translate(0 0)">
                                  <g id="Group_23700" data-name="Group 23700" transform="translate(0 0)" clip-path="url(#clip-path)">
                                    <path id="Path_51546" data-name="Path 51546" d="M1.019,7.044H.54a.51.51,0,1,1,0-1.018H11.675a.511.511,0,1,1,0,1.018h-.463v.2q0,4.563,0,9.126A2.088,2.088,0,0,1,9.381,18.5a1.806,1.806,0,0,1-.27.011q-2.994,0-5.989,0a2.081,2.081,0,0,1-2.1-2.1q0-4.579,0-9.158Zm9.176.009H2.038v.164q0,4.554,0,9.108a1.085,1.085,0,0,0,1.174,1.169H9.024a1.086,1.086,0,0,0,1.17-1.172q0-4.546,0-9.092Z" transform="translate(0 -4.497)" fill="#fd4d5d"/>
                                    <path id="Path_51547" data-name="Path 51547" d="M16.609,0H18.6a.511.511,0,1,1,0,1.018H14.619A.509.509,0,0,1,14.062.5a.508.508,0,0,1,.556-.5h1.991" transform="translate(-10.494 0)" fill="#fd4d5d"/>
                                    <path id="Path_51548" data-name="Path 51548" d="M16.084,22.14q0,1.25,0,2.5a.511.511,0,1,1-1.018,0q0-2.5,0-5a.511.511,0,1,1,1.018,0q0,1.25,0,2.5" transform="translate(-11.243 -14.24)" fill="#fd4d5d"/>
                                    <path id="Path_51549" data-name="Path 51549" d="M30.146,22.156q0,1.242,0,2.485a.511.511,0,1,1-1.018,0q0-2.5,0-5a.511.511,0,1,1,1.018,0q0,1.258,0,2.516" transform="translate(-21.736 -14.24)" fill="#fd4d5d"/>
                                  </g>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ValidationObserver ref="adminOrderNoteObserver" v-slot="{ handleSubmit }">
                    <form>
                      <ValidationProvider name="order note" :rules="{regex:/^\S.*\S$/,max:1000}" v-slot="{ errors }">
                        <div class="addOrderNoteFieldBox" id="orderDetailsSection">
                          <input
                            type="text"
                            name="orderNote"
                            id="orderNote"
                            v-model="tempOrderNote"
                            :disabled="tourIsActive || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'))"
                          />
                          <button 
                            @click.prevent="handleSubmit(addOrderNote)"
                            :disabled="tourIsActive || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'))"
                            >Add</button>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <!-- Shipping Block -->
            <div class="productTableInfoWhiteBox" id="shipping-options-section">
              <div class="productTableInfoWhiteBoxTitle">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <h5>Select Shipping</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="ctmShippingLink" v-if="!tourIsActive">
                      <a href="#customShippingBlock">Apply Custom Shipping Charges</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="storeShippingCheckBox">
                <p>{{ shippingOptions.type }}</p>
                <div class="storeShippingCheckFlexBox">
                  <div class="storeShippingCheckField" v-for="(rule, index) in shippingOptions.rules" :key="index" >
                    <!--Flat Rate Handle-->
                    <label :for="`${rule.id + '-' + rule.name}`" v-if="shippingOptions.type == 'Flat Rate'">
                      <input 
                        type="radio" 
                        :name="`${rule.id + '-' + rule.name}`" 
                        :id="`${rule.id + '-' + rule.name}`" 
                        :value="rule" 
                        v-model="selectedShipping"
                        :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.business_delivery }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="shippingOptions.isNonCODDifferent && selectedOrder.payment.payment_method.type != 'Cash on Delivery'">
                          <p>Rs. {{ rule.non_cod_price }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <p>Rs. {{ rule.price }}</p>
                        </div>
                      </div>
                    </label>
                    <!--Store Pickup Only Handle-->
                    <label :for="`${rule.id + '-' + rule.name}`" v-if="shippingOptions.type == 'Store Pickup Only'">
                      <input
                        type="radio" 
                        :name="`${rule.id + '-' + rule.name}`" 
                        :id="`${rule.id + '-' + rule.name}`" 
                        :value="{ shipping_name: shippingOptions.type, rule: rule,}" 
                        v-model="selectedShipping"
                        :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.number }}</p>
                        </div>
                        <div class="col-md-6 text-right">
                          <p>{{ rule.city }}</p>
                        </div>
                      </div>
                      <p class="mt-2">{{ rule.address }}</p>
                    </label>
                    <!--Rate By Price Handle-->
                    <label :for="`${rule.id + '-' + rule.name}`" v-if="shippingOptions.type == 'Rate By Price'">
                      <input 
                        type="radio" 
                        :name="`${rule.id + '-' + rule.name}`" 
                        :id="`${rule.id + '-' + rule.name}`" 
                        :value="{ shipping_name: shippingOptions.type, rule: rule,}" 
                        v-model="selectedShipping"
                        :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.business_delivery }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="shippingOptions.isNonCODDifferent && selectedOrder.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(price, index) in rule.prices" :key="index + 'p'">
                            <p v-if="selectedOrder.payment.amount >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= selectedOrder.payment.amount">
                            {{ selectedOrder.payment.amount >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= selectedOrder.payment.amount ? parseFloat(price.non_cod_price).toFixed(2) : ""}}
                            </p>
                            <p v-else-if="selectedOrder.payment.amount > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                            {{ parseFloat(price.non_cod_price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(price, index) in rule.prices" :key="index + 'p'">
                            <p v-if="selectedOrder.payment.amount >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= selectedOrder.payment.amount">
                            {{ selectedOrder.payment.amount >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= selectedOrder.payment.amount ? parseFloat(price.price).toFixed(2) : ""}}
                            </p>
                            <p v-else-if="selectedOrder.payment.amount > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                            {{ parseFloat(price.price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>
                    <!--Rate By Weight Handle-->
                    <label :for="`${rule.id + '-' + rule.name}`" v-if="shippingOptions.type == 'Rate By Weight'">
                      <input 
                        type="radio" 
                        :name="`${rule.id + '-' + rule.name}`" 
                        :id="`${rule.id + '-' + rule.name}`" 
                        :value="{ shipping_name: shippingOptions.type,rule: rule,}" 
                        v-model="selectedShipping"
                        :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.business_delivery }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="shippingOptions.isNonCODDifferent && selectedOrder.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(weight, index) in rule.weight" :key="index + 'w'">
                            <p v-if="parseFloat(selectedOrder.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(selectedOrder.total_weight)">
                              {{ parseFloat(selectedOrder.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(selectedOrder.total_weight) ? parseFloat(weight.non_cod_price).toFixed(2) : "" }}
                            </p>
                            <p v-else-if=" parseFloat(selectedOrder.total_weight) > parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ parseFloat(weight.non_cod_price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(weight, index) in rule.weight" :key="index + 'w'">
                            <p v-if="parseFloat(selectedOrder.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(selectedOrder.total_weight)">
                              {{ parseFloat(selectedOrder.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(selectedOrder.total_weight) ? parseFloat(weight.price).toFixed(2) : "" }}
                            </p>
                            <p v-else-if=" parseFloat(selectedOrder.total_weight) > parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ parseFloat(weight.price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Order Detail Block -->
            <div class="productTableInfoWhiteBox" id="orderDetail">
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Order Detail</h5>
              </div>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="order date" rules="required" v-slot="{ errors }">
                        <label for="orderDate">Order Date<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="date"
                            name="orderDate"
                            id="orderDate"
                            onkeydown="return false"
                            @input="checkDate()"
                            v-model="selectedOrder.order_date"
                            :max="minDate"
                            :disabled="tempOrder.created_by == 'customer' || tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tableFromFieldBox" id="customShippingBlock">
                      <ValidationProvider
                        name="delivery date"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label for="deliveryDate">Delivery Date<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="date"
                            name="deliveryDate"
                            id="deliveryDate"
                            onkeydown="return false"
                            @input="checkDate()"
                            :min="selectedOrder.order_date"
                            v-model="selectedOrder.delivery_date"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tableFromFieldBox">
                      <label for="paymentDropDown">Payment Method<span>*</span></label>
                      <div class="vueSelectBlock">
                        <Multiselect
                          v-model="selectedOrder.payment.payment_method"
                          id="paymentDropDown"
                          :options="payment_method_list"
                          placeholder="Select Payment Method"
                          label="type"
                          track-by="id"
                          :object="true"
                          :allow-empty="false"
                          :hide-selected="false"
                          @close="onTouch"
                          :disabled="tempOrder.created_by == 'customer' || tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                        <span class="text-danger" v-show="fieldRequired">The payment method field is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                        <label for="statusDropDown">Status<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedOrder.status"
                            id="statusDropDown"
                            :options="statusList"
                            placeholder="Select Status"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="tableFromFieldBox">
                      <label for="cShippingCharges">Custom Shipping Charges</label>
                      <div class="addOrderNoteFieldBox">
                        <input
                          type="number"
                          name="cShippingCharges"
                          id="cShippingCharges"
                          v-model="customShippingCharges"
                          :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                        <button 
                          @click.prevent="applyCustomShipping()"
                          :disabled="tourIsActive || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'))"
                          >
                          Add
                        </button>
                      </div>
                      <p class="fieldSeoMessage">
                        <span style="display: inline-flex;margin-right: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                          </svg> 
                        </span>
                        If Custom Shipping Charges will be added, the selected
                        shipping option will not be considered
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <form>
                      <div class="tableFromFieldBox">
                        <label for="couponCode">Coupon</label>
                        <div class="addOrderNoteFieldBox coupon">
                          <input
                            type="text"
                            name="couponCode"
                            id="couponCode"
                            style="padding-right: 190px !important"
                            v-model="selectedOrder.coupon_code"
                            :readonly="selectedOrder.isUpdate"
                            :disabled="tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <loaderBtn v-if="couponLoader"/>
                          <button 
                            @click.prevent="verifyCoupon()" 
                            :disabled="tourIsActive || selectedOrder.isUpdate || selectedOrder.id != -1 && (selectedOrder.status == 'Delivered' || selectedOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                            v-else
                          >
                            <p>{{ selectedOrder.isUpdate ? "Verified" : "Verify Coupon" }}</p>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </form>
            </div>
            <!-- Order Total Block -->
            <div class="productTableInfoWhiteBox" id="order-total-section">
              <div class="priceDetailList">
                <ul>
                  <li>
                    <h4>Total Purchased Products</h4>
                    <h2>{{ calTotalProduct }}</h2>
                  </li>
                  <li>
                    <h4 class="greenColorTxt">Amount</h4>
                    <h2 class="greenColorTxt" style="font-weight: 500">
                      {{
                        selectedOrder.payment.amount
                          ? settings.currency_unit ? settings.currency_symbol + " " + selectedOrder.payment.amount.toLocaleString(
                              "ur-PK",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : settings.currency_symbol + " " +(0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + selectedOrder.payment.amount.toLocaleString(
                              "ur-PK",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )

                      }}
                    </h2>
                  </li>
                  <li v-if="isCustomTax">
                    <h4 style="font-weight: 300">Tax Percentage</h4>
                    <div class="taxDetailTxt">
                      <div class="quantityTaxDetail">
                        <button class="miniusIconBtn" 
                          @click=" selectedOrder.payment.tax > 0 ? selectedOrder.payment.tax-- : selectedOrder.payment.tax"
                          :disabled="tourIsActive || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'))">
                          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <g id="_-" data-name=" -" class="cls-1">
                                <g class="cls-1">
                                  <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                        <input 
                          type="number" 
                          name="stock" 
                          id="stock" 
                          placeholder="0" 
                          v-model="selectedOrder.payment.tax" 
                          @blur="handleTaxInput()"
                          :disabled="tourIsActive || (selectedOrder.id != -1 && (selectedOrder.status == 'Delivered' || selectedOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'))"/>
                        <button 
                        class="plusIconBtn" 
                        @click=" selectedOrder.payment.tax < 100 ? selectedOrder.payment.tax++ : selectedOrder.payment.tax"
                        :disabled="tourIsActive || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled'))">
                          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <g id="_" data-name=" " class="cls-1">
                                <g class="cls-1">
                                  <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>           
                      </div>
                      <a href="#." @click.prevent="isCustomTax = !isCustomTax">Save</a>
                    </div>
                  </li>
                  <li v-else>
                    <h4 style="font-weight: 300">Tax Percentage</h4>
                    <div class="taxDetailTxt">
                      <div class="quantityTaxDetail">
                        
                      </div>
                      <p>{{ selectedOrder.payment.tax }}%</p>
                      <a href="#." @click.prevent="isCustomTax = !isCustomTax" id="custom-tax-btn" v-if="tempOrder.status != 'Deleted' && tempOrder.status != 'Cancelled' && tempOrder.status != 'Delivered' && tempOrder.status != 'Refunded' ">Add Custom Tax</a>
                    </div>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Tax Amount</h4>
                    <h2 style="font-weight: 500">
                      {{ settings.currency_unit ? settings.currency_symbol + " " +
                        parseFloat(
                          selectedOrder.payment.amount *
                            (selectedOrder.payment.tax / 100)
                        ).toLocaleString("ur-PK", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) 
                        : ''
                      }}
                    </h2>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Shipping Fee</h4>
                    <h2 style="font-weight: 500">
                      {{
                        selectedOrder.payment.shipping_amount
                          ? settings.currency_unit ? settings.currency_symbol + " " +parseFloat(
                              selectedOrder.payment.shipping_amount
                            ).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(selectedOrder.payment.shipping_amount).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }}
                    </h2>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Discounted Amount</h4>
                    <h2 style="font-weight: 500">
                      {{
                        selectedOrder.discountedAmount
                          ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(
                              selectedOrder.discountedAmount
                            ).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }}
                    </h2>
                  </li>
                  <li>
                    <div class="netTotalBlock">
                      <p>Net Total</p>
                      <h5>
                        {{
                          selectedOrder.payment.net_amount
                            ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(
                                selectedOrder.payment.net_amount
                              ).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : settings.currency_symbol + " " + (selectedOrder.payment.net_amount).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }}
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </ValidationObserver>
      <!-- order add/update form starts here-->

    </div>

    <!-- new table ends here-->

    <!-- bottom action bar start -->
    <div class="row product-bottom-bar" v-show="checkedItems.length > 1">
      <div class="col-md-6 model-footer-main1">
        <div class="bottom-bar">
          <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != orders.length">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <p>SELECT ALL</p>
          </a>
          <a href="" class="model-footer-1" @click.prevent="checkedItems = []">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
            <p>CLEAR SELECTION</p>
          </a>
        </div>
        <div class="model-footer-2">{{ checkedItems.length }}</div>
      </div>
      <div class="col-md-6 model-footer-main1 product-row-reverse">
        <div class="bottom-bar">
          <div class="select-box">
            <select name="globalTag" id="selectGlobalTag" v-model="selectedStatus">
              <option value="" disabled selected>-- Assign Status --</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Dispatched">Dispatched</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Refunded">Refunded</option>
            </select>
            <button @click="applyAction('statusChange')">Apply</button>
          </div>
          <a href="" class="model-footer-1 ml-4" @click.prevent="applyAction('bulkDelete')">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
            <p>DELETE</p>
          </a>
        </div>
      </div>
    </div>
    <!-- bottom action bar end -->

    <!-- order product list modal starts here-->
    <div class="modalBg addProductModel" :class="product_model.isOpen ? 'modal-active' : ''">
      <div class="modalLayers" @click.prevent="productsAvailable"></div>
      <div class="modalBody">
        <div class="addProductTitle">
          <h3>ADD PRODUCTS</h3>
        </div>
        <div class="searchProductForm">
          <input
            type="text"
            name="searchProduct"
            id="searchProduct"
            placeholder="Search product"
            v-model="searchedProduct"
            @input="searchProduct()"
          />
        </div>
        <div class="productMainSelectBox">
          <div class="showProductBox" v-if="productSearchLoader">
            <div class="productGrayBox" v-for="i in 3" :key="i">
              <vue-skeleton-loader 
                type="rect" 
                :width="620" 
                :height="125" 
                animation="fade"
              />
            </div>
          </div>
          <div class="showProductBox" v-else>
            <div class="productGrayBox" v-if="product_list.length == 0" style="justify-content: center !important">
              <p style="color: #000">
                No Active Products Found Against "{{ searchedProduct }}"
              </p>
            </div>
            <div class="productGrayBox" v-for="product in product_list" :key="product.id">
              <div class="productGrayBoxImg">
                <img :src="product.image ? base_image_url+ settings.store_id + '/product/' + product.image : '/images/no_image.png'" alt="" />
              </div>
              <div class="productGrayBoxdetail">
                <div class="productNameDetail">
                  <div class="ProductNameTxt">
                    <p>{{ product.name }}</p>
                  </div>
                </div>
                <div class="productSkuBlock">
                  <div class="productSkuWhiteBox" :class="variant.qty <= 0 || !variant.isActive ? 'stockOutProduct': ''" v-for="variant in product.variants" :key="variant.id" 
                    :data-toggle="variant.qty <= 0 ? 'tooltip' : ''" :data-placement="variant.qty <= 0 ? 'right' : ''" :title="variant.qty <= 0 ? 'Out of Stock' : !variant.isActive ? 'Variant Inactive': '' ">
                    <h5>
                      {{ variant.name }}
                    </h5>
                    <p>{{ variant.sku }}</p>
                    <p class="productPrice" v-if="variant.sale_price">
                      {{ variant.sale_price ? settings.currency_symbol + " " + parseFloat(variant.sale_price).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0 
                        }}
                    </p>
                    <p class="productPrice" v-else>
                      {{ variant.price ? settings.currency_symbol + " " + parseFloat(variant.price).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0 
                      }}
                    </p>
                    <button
                      @click="
                        addVariant({
                          product_id: product.id,
                          name: product.name,
                          type: product.product_type,
                          image: product.image,
                          variant: {
                            id: variant.id,
                            name: variant.name,
                            qty: 1,
                            stock: variant.qty,
                            price: variant.sale_price,
                            weight: variant.weight,
                            sku: variant.sku,
                          },
                        })
                      "
                      :disabled="variant.qty <= 0 || !variant.isActive"
                      v-if="variant.sale_price"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                        <g id="plus" transform="translate(0)">
                          <path id="Path_52" data-name="Path 52" d="M14.625,7.875a.75.75,0,0,1,.75.75v6a.75.75,0,0,1-.75.75h-6a.75.75,0,0,1,0-1.5h5.25V8.625A.75.75,0,0,1,14.625,7.875Z" transform="translate(-7.875 -7.875)" fill-rule="evenodd"/>
                          <path id="Path_53" data-name="Path 53" d="M16.875,17.625a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,0,1.5h-5.25v5.25a.75.75,0,0,1-1.5,0Z" transform="translate(-10.875 -10.875)" fill-rule="evenodd"/>
                        </g>
                      </svg>
                    </button>
                    <button
                      @click="
                        addVariant({
                          product_id: product.id,
                          name: product.name,
                          type: product.product_type,
                          image: product.image,
                          variant: {
                            id: variant.id,
                            name: variant.name,
                            qty: 1,
                            stock: variant.qty,
                            price: variant.price,
                            weight: variant.weight,
                            sku: variant.sku,
                          },
                        })
                      "
                      :disabled="variant.qty <= 0 || !variant.isActive"
                      v-else
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                        <g id="plus" transform="translate(0)">
                          <path id="Path_52" data-name="Path 52" d="M14.625,7.875a.75.75,0,0,1,.75.75v6a.75.75,0,0,1-.75.75h-6a.75.75,0,0,1,0-1.5h5.25V8.625A.75.75,0,0,1,14.625,7.875Z" transform="translate(-7.875 -7.875)" fill-rule="evenodd"/>
                          <path id="Path_53" data-name="Path 53" d="M16.875,17.625a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,0,1.5h-5.25v5.25a.75.75,0,0,1-1.5,0Z" transform="translate(-10.875 -10.875)" fill-rule="evenodd"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="addProductShowBox">
            <div class="addProductTitle">
              <h3>ADDED PRODUCTS</h3>
            </div>
            <div class="productDelList">
              <ul>
                <li v-for="(item, index) in selectedOrder.items" :key="index">
                  <div class="productListDetail">
                    <div>
                      <h5>{{ item.name }}</h5>
                      <p>{{ item.variant.name }}</p>
                      <p>{{ item.variant.sku }}</p>
                    </div>
                    <p class="productListDetailPrice">
                      {{ item.variant.price ? settings.currency_symbol + " " + parseFloat(item.variant.price).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0 
                      }}
                    </p>
                  </div>
                  <div class="delIconBox">
                    <button @click="removeItem(item, 'productModal', index)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25.667" viewBox="0 0 22 25.667">
                        <g id="Group_175" data-name="Group 175" transform="translate(-1781 -573.417)">
                          <path id="Path_68" data-name="Path 68" d="M13.5,13.5h1.833v11H13.5Z" transform="translate(1774.833 569.083)" fill="#fd4d5d"/>
                          <path id="Path_69" data-name="Path 69" d="M20.25,13.5h1.833v11H20.25Z" transform="translate(1773.583 569.083)" fill="#fd4d5d"/>
                          <path id="Path_70" data-name="Path 70" d="M4.5,6.75V8.583H6.333V26.917A1.833,1.833,0,0,0,8.167,28.75H22.833a1.833,1.833,0,0,0,1.833-1.833V8.583H26.5V6.75ZM8.167,26.917V8.583H22.833V26.917Z" transform="translate(1776.5 570.333)" fill="#fd4d5d"/>
                          <path id="Path_71" data-name="Path 71" d="M13.5,2.25h7.333V4.083H13.5Z" transform="translate(1774.833 571.167)" fill="#fd4d5d"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modalDoneButton">
          <button @click.prevent="productsAvailable" :disabled="selectedOrder.items.length == 0">Done</button>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="productsAvailable">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
              <path id="close" d="M31,10.925,29.075,9,20,18.075,10.925,9,9,10.925,18.075,20,9,29.075,10.925,31,20,21.925,29.075,31,31,29.075,21.925,20Z" transform="translate(-9 -9)" fill="#22678d"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- order product list modal ends here-->

    <!-- update Order Note modal starts here-->
    <div class="modalBg passwordResetPopup" :class="orderNoteForm.isOpen ? 'modal-active' : ''">
      <div class="modalLayers" @click.prevent="orderNoteForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;overflow: hidden !important;">
          <ValidationObserver ref="customerUpdatePasswordObserver" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateOrderNote)">
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider name="order note" :rules="{required:true,regex:/^\S.*\S$/}" vid="password" v-slot="{ errors }">
                    <div class="tableFromFieldBox">
                      <label class="formLabelCustomer">Order Note <span>*</span></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="orderNoteForm.updatedOrderNote"
                        placeholder="Order Note*"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-12"></div>
                <div class="col-md-12">
                  <div class="formCustomerBtn">
                    <button type="submit">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="orderNoteForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- update Order Note modal ends here-->

    <!-- add customer modal starts here-->
    <ValidationObserver ref="orderCustomerFormObserver">
      <div class="modalBg" :class="customer.isOpen ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="resetValidator"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; top: 0px; right: 0; width: 100%">
            <div id="London" class="tabcontent">
              <div class="contaient-div horizontal-scroll-fix">
                <div class="titleActiveBox">
                  <div class="customer-detail">
                    <h2 style="margin-bottom: 0px !important;">CUSTOMER DETAIL</h2>
                  </div>
                  <div class="shipping-table">
                    <table class="table">
                      <tr>
                        <td class="p-status-flex">
                          <p class="p2" style="margin-right: 0px !important;">{{customer.isActive ? 'ACTIVE' : 'INACTIVE'}}</p>
                          <div class="actionSwitch" style="display: inline-flex;align-items: center;">
                            <label class="switch">
                              <input
                                type="checkbox"
                                id="addcustomer"
                                v-model="customer.isActive"
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="uploadNewUserImg">
                  <div class="userDetailsImg">
                    <img :src=" customer.image_src ? customer.image_src : '/images/customer_noImage.png'" alt=""/>
                  </div>
                  <div class="uploadImgFile">
                    <input 
                      type="file" 
                      accept="image/png,image/jpg,image/jpeg,image/webp"
                      @change.prevent="changeAddImage" />
                    <button>
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <form class="customerDetailFormBox" @submit.prevent="validate('addCustomer')">
                  <div class="row">
                    <div class="col-md-6">
                      <ValidationProvider name="first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">First Name <span>*</span></label>
                          <div class="formContentBox">
                            <input
                              type="text"
                              class="form-control"
                              v-model="customer.first_name"
                              @input="handleFormat('f_name')"
                              placeholder="First Name"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">Last Name <span>*</span></label>
                          <div class="formContentBox">
                            <input
                              type="text"
                              class="form-control"
                              v-model="customer.last_name"
                              @input="handleFormat('l_name')"
                              placeholder="Last Name"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="email" rules="required|email|max:250" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">Email <span>*</span></label>
                          <div class="formContentBox">
                            <input
                              type="email"
                              class="form-control"
                              v-model="customer.email"
                              placeholder="Email"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="password" :rules="{required:true,min:8,max:250}" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">Password <span>*</span></label>
                          <div class="tableFromFieldItem passwordBtnIconPd">
                            <a href="#." class="genPasswordBtn" @click.prevent="generatePassword('sliderForm',16)">Generate Password</a>
                            <input
                              :type="customer.passwordShow ? 'text' : 'password'"
                              class="form-control"
                              v-model="customer.password"
                              placeholder="Password"
                              autocomplete="true"
                              ref="passwordInput"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                            <button type="button" class="passwordEyeBtn" @click.prevent="customer.passwordShow = !customer.passwordShow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><g id="eyeOpen" transform="translate(-355 -428)"><g id="eye-svgrepo-com" transform="translate(355 333.275)"><path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"></path></g><rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none"></rect></g></svg></button>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4" id="mobile-no-field">
                      <ValidationProvider name="mobile no." :rules="countryIsSelected ? 'required' : '' " v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">Mobile No <span>*</span></label>
                          <div class="formContentBox">
                            <vue-tel-input 
                              v-model="customer.mobile_no"
                              mode="international"
                              :onlyCountries="allowedCountries"
                              :inputOptions="ContactFieldOptions"
                              ref="contactNoField"
                              :dropdownOptions="ContactFieldDropDownOptions"
                              :validCharactersOnly=true 
                              @input="contactInputHandle"
                              @validate="validateContactNo"
                              @country-changed="countryChangeHandle"
                              @focus="contactFieldFocusHandle"
                              @close="dropDownCloseHandle"
                            >
                            </vue-tel-input>
                            <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                            <span class="text-danger" v-else-if="!countryIsSelected && contactFieldTouched">Select a country from the dropdown menu or add the country code before the contact no.</span>
                            <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">Enter a valid mobile no.</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">Country <span>*</span></label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              v-model="selectedCountry"
                              :clearOnSelect="true"
                              :options="countriesList"
                              label="name"
                              :multiple="false"
                              :object="true"
                              track-by="name"
                              :hideSelected="false"
                              :showNoResults="true"
                              placeholder="Select Country"
                              :showOptions="true"
                              :searchable="true"
                              openDirection="bottom"
                            >
                            </Multiselect>
                            <span class="text-danger" >{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">State <span>*</span></label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              v-model="selectedState"
                              :clearOnSelect="true"
                              :options="listStates ? listStates : []"
                              label="name"
                              track-by="name"
                              :hideSelected="false"
                              :showNoResults="true"
                              :multiple="false"
                              :object="false"
                              :showOptions="false"
                              :searchable="true"
                              openDirection="bottom"
                              placeholder="Select State"
                            >
                            <span slot="noOptions">A country must be selected</span>
                            <span slot="noResult">No state found matching the searched keyword</span>
                            </Multiselect>
                            <span class="text-danger" >{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">City <span>*</span></label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              v-model="selectedListCity"
                              :clearOnSelect="true"
                              :options="listCities ? listCities : []"
                              label="name"
                              track-by="name"
                              :hideSelected="false"
                              :showNoResults="true"
                              :multiple="false"
                              :object="true"
                              :showOptions="true"
                              :searchable="true"
                              openDirection="bottom"
                              placeholder="Select City"
                            >
                            <span slot="noOptions">A state must be selected</span>
                            <span slot="noResult">No city found matching the searched keyword</span>
                            </Multiselect>
                            <span class="text-danger" >{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="address line 1" rules="required|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="">Address Line 1 <span>*</span></label>
                        <div class="formContentBox">
                          <input
                            type="text"
                            class="form-control"
                            v-model="customer.address_line_1"
                            placeholder="Address line 1"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="address line 2" rules="max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="">Address Line 2</label>
                        <div class="formContentBox">
                          <input
                            type="text"
                            class="form-control"
                            v-model="customer.address_line_2"
                            placeholder="Address line 2"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12">
                      <div class="formCustomerBtn">
                        <loaderBtn v-if="customer.isLoading"/>
                        <button v-else type="submit">Save</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="resetValidator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
            </button>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <!-- add customer modal ends here-->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import _ from "lodash";
import { Message, MessageBox } from "element-ui";
import Swal from 'sweetalert2';
import loaderBtn from "./loaderBtn.vue";

export default {
  components: {
    Multiselect,
    loaderBtn
  },
  name: "OrderTable",
  customerProfile: null,
  data: () => ({
    base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
    statusList: [
      "Paid",
      "Pending",
      "Confirmed",
      "Dispatched",
      "Delivered",
      "Cancelled",
      "Refunded",
    ],
    selectedOrder: {
      id: "",
      index: "",
      order_date: "",
      order_no: "",
      delivery_date: "",
      status: "",
      coupon_code: "",
      order_notes: "",
      total_products: "",
      shipping: {
        shipping_first_name:"",
        shipping_last_name:"",
        shipping_email:"",
        shipping_contact_no:"",
        shipping_id: '',
        shipping_rule_id: '',
        shipping_amount:0,
      },
      billing:{
        billing_first_name:"",
        billing_last_name:"",
        billing_email:"",
        billing_contact_no:"",
      },
      items: [],
      customer: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        mobile_no: "",
      },
      total_weight: "",
      payment: {
        id: "",
        amount: "",
        net_amount: "",
        shipping_name: "",
        shipping_amount: 0,
        selected_option: "",
        package_name: "",
        status: "",
        tax: 0,
        payment_method: {
          id: "",
          type: "",
        },
      },
      activities: [],
      notes: [],
      discountedAmount: 0,
      isUpdate: false,
      isTouched: false,
    },
    form: {
      id: "",
      index: "",
      order_date: "",
      order_no: "",
      delivery_date: "",
      status: "",
      coupon_code: "",
      order_notes: "",
      total_products: "",
      shipping: {
        shipping_first_name:"",
        shipping_last_name:"",
        shipping_email:"",
        shipping_contact_no:"",
        shipping_id: '',
        shipping_rule_id: '',
        shipping_amount:0,
      },
      billing:{
        billing_first_name:"",
        billing_last_name:"",
        billing_email:"",
        billing_contact_no:"",
      },
      items: [],
      customer: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        mobile_no: "",
      },
      total_weight: "",
      payment: {
        id: "",
        amount: "",
        net_amount: "",
        shipping_name: "",
        shipping_amount: 0,
        selected_option: "",
        package_name: "",
        status: "",
        tax: 0,
        payment_method: {
          id: "",
          type: "",
        },
      },
      activities: [],
      notes: [],
      discountedAmount: 0,
      isUpdate: false,
      isTouched: false,
    },
    isUpdate: false,
    value: {
      first_name: "",
      id: "",
      email: "",
      mobile_no: "",
    },
    list_customer: [],
    tempCustomersList:[],
    orders: [],
    product_list: [],
    product_model: {
      isOpen: false,
    },
    payment_method_list: [],
    boxisLoading: false,
    orderLoader: false,
    couponLoader: false,
    productSearchLoader: false,
    fieldRequired: false,
    customerFieldRequired: false,
    hasProducts: false,
    regions: [],
    matchedRegion:{},
    shippingOptions: [],
    selectedShipping: "",
    coupon: [],
    searchedProduct: "",
    customShippingCharges: null,
    tempOrder: [],
    orderUpdate: false,
    tempOrderNote: "",
    tempOrderNotes: [],
    selectedOrderNote: "",
    orderNoteForm: {
      isOpen: false,
      updatedOrderNote: "",
      index: "",
    },
    customer: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      mobile_no: "",
      city: null,
      address_line_1: "",
      address_line_2: "",
      image: "",
      image_src: "",
      isLoading: false,
      isActive: true,
      isOpen: false,
      passwordShow:false,
    },
    shipping_address: "",
    billing_address: "",
    storeCities: [],
    selectedCity: "",
    checkedItems: [],
    deletingItems:[],
    deleteLoader:false,
    selectedStatus: "",
    isCustomTax: false,
    isCreateNew:false,
    isMasterSearched:false,
    countriesList: [],
    selectedCountry: null,
    listStates: [],
    selectedState: null,
    selectedFormState: [],
    listCities: [],
    selectedListCity: null,
    tempSelectedCity:null,
    tempSelectedState: null,
    //billing
    isSameBilling:true,
    selectedBillingCountry: null,
    listBillingCountries:[],
    selectedBillingState: null,
    listBillingStates:[],
    selectedBillingCity: null,
    listBillingCities:[],
    tourIsActive:false,
    imageValidations:{
      imgIsRequired:false,
      imgInvalidFormat:false,
      isNotImg:false,
    },
    //add customer contact field handle
    allowedCountries:[],
    ContactFieldOptions:{
      placeholder:"Enter mobile number",
      showDialCode:true,
      type:'tel',
      maxlength:25,
    },
    ContactFieldDropDownOptions:{
      showSearchBox:true,
      showDialCodeInSelection:false,
      showDialCodeInList:true,
      showFlags:true,
      placeholder:"Select a Country"
    },
    contactIsValid:true,
    countryIsSelected:false,
    contactFieldTouched:false,
    selectedContactObj:{},
    selectedContactCountry:[],
    //shipping contact handle
    shippingContactIsValid:true,
    shippingCountryIsSelected:false,
    shippingContactFieldTouched:false,
    shippingSelectedContactObj:{},
    shippingSelectedContactCountry:[],
    //billing contact handle
    billingContactIsValid:true,
    billingCountryIsSelected:false,
    billingContactFieldTouched:false,
    billingSelectedContactObj:{},
    billingSelectedContactCountry:[],
  }),
  methods: {
    onChangeSameBilling(e){

      if(this.isSameBilling){

        this.selectedBillingCountry = this.selectedCountry;
        this.selectedBillingState = this.selectedFormState;
        this.selectedBillingCity = this.selectedCity;
        
        this.selectedOrder.billing.billing_first_name = this.selectedOrder.shipping.shipping_first_name;
        this.selectedOrder.billing.billing_last_name = this.selectedOrder.shipping.shipping_last_name;
        this.selectedOrder.billing.billing_email = this.selectedOrder.shipping.shipping_email;
        this.selectedOrder.billing.billing_contact_no = this.selectedOrder.shipping.shipping_contact_no;
        this.billing_address = this.shipping_address;

      }else{

        this.selectedBillingCountry = null
        this.selectedBillingState = null
        this.selectedBillingCity = null

        this.selectedOrder.billing.billing_first_name = ""
        this.selectedOrder.billing.billing_last_name = ""
        this.selectedOrder.billing.billing_email = ""
        this.selectedOrder.billing.billing_contact_no = ""

        this.billing_address = ""

      }

    },
    onChangeInField(field){

      if(this.isSameBilling){

        if(field == 'first_name'){

          this.selectedOrder.billing.billing_first_name = this.selectedOrder.shipping.shipping_first_name;

        }

        if(field == 'last_name'){

          this.selectedOrder.billing.billing_last_name = this.selectedOrder.shipping.shipping_last_name;

        }

        if(field == 'email'){

          this.selectedOrder.billing.billing_email = this.selectedOrder.shipping.shipping_email;

        }

        if(field == 'contact_no'){

          this.selectedOrder.billing.billing_contact_no = this.selectedOrder.shipping.shipping_contact_no;

        }

        if(field == 'address'){

          this.billing_address = this.shipping_address;

        }

      }

    },
    copyOrderNo(order){

      const storage = document.createElement("textarea");

      storage.value = order.order_no;

      this.$refs.newOrdertable.appendChild(storage);

      storage.select();

      storage.setSelectionRange(0, 99999);

      document.execCommand("copy");

      this.$refs.newOrdertable.removeChild(storage);

      this.$notify({
        type: "success",
        title: "Success",
        message: "Order no. has been successfully copied to clipboard.",
      });

    },
    addVariant(variantItem){

      let isFound = false;

      this.selectedOrder.items.forEach((item) => {

        if(item.variant.id == variantItem.variant.id){

          isFound = true;

        }

      });

      if(!isFound){

        this.selectedOrder.items.push(variantItem);

      }else{

        this.$notify({
          type: "info",
          title: "Alert",
          message: "Product has already been selected.",
        });

      }

    },
    //product related methods
    async OpenProductModal(){

      // this.searchedProduct = ""
      // this.searchProduct().then(
        this.product_model.isOpen = true;
      // )

    },
    productsAvailable(){

      this.hasProducts = true;
      this.product_model.isOpen = false;

      if(this.searchedProduct.length > 0){

        this.searchedProduct = ""
        this.searchProduct();

      }

    },
    async searchProduct(){

      this.productSearchLoader = true;

      clearTimeout(this.timer);

      this.timer = setTimeout( async ()=>{
        let res = await this.$axios.get(`/getAllProducts`,{
          params:{
            search: this.searchedProduct
          }
        });
        if(res.data.status_code == "1007"){

          this.productSearchLoader = false;

          if(res.data.products.length > 0){

            this.product_list = res.data.products;

          }else{

            this.product_list = [];

          }

          return true

        }

      },500);

    },
    //load more method
    handleScrollEvent(event){

      let element = this.$refs.newOrdertable;

      let maxScrollTop = element.scrollHeight - element.clientHeight;

      if(element.scrollTop >= maxScrollTop - 100){

        if(this.isPending == false  && this.storeOrdersCurrentPage != this.storeOrdersLastPage){

          this.moreOrdersLoader = true;

          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: this.storeOrdersCurrentPage + 1, loadData: 'more'});

        }

      }

    },
    //coupon verification method
    async verifyCoupon(){

      if(!this.selectedOrder.coupon_code){

        this.$notify({
          type: "info",
          title: "Coupon Code Missing",
          message: "Enter coupon code to proceed with the verification process.",
        });

        return

      }
      if(this.selectedOrder.items.length == 0){

        this.$notify({
          type: "info",
          title: "Products Not Selected",
          message: "At least one product must be selected.",
        });

        return

      }
      if(this.coupon.length > 0 && this.coupon[0].coupon_code == this.selectedOrder.coupon_code){

        this.$notify({
          type: "info",
          title: "Coupon Already Verified",
          message: "Coupon has already been verified.",
        });

        return

      }

      if(this.selectedOrder.customer.email.length == 0){

        this.$notify({
          type: "info",
          title: "Customer Not Selected.",
          message: "Customer must be selected first.",
        });

        return

      }
      this.couponLoader = true;
      this.coupon = [];
      let formData = new FormData();

      formData.append("coupon", this.selectedOrder.coupon_code);
      formData.append("email", this.selectedOrder.customer.email);
      try{
        let res = await this.$axios.post("/validateCoupon", formData);
        if(res.data.status_code == "1074"){

          this.couponLoader = false;

          this.coupon.push(res.data.coupon);
          this.$notify({
            type: "success",
            title: "Success",
            message: "Entered coupon has been successfully verified.",
          });

        }
      }catch(error){

        this.couponLoader = false;
        if(error.response){

          if(typeof(error.response.data.error) == 'string' &&  
          error.response.data.error.includes("expired")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });
            
          }else if(typeof(error.response.data.error) == 'string' && 
          error.response.data.error.includes("not available")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else if(typeof(error.response.data.error) == 'string' && 
          error.response.data.error.includes("reached")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else if(typeof(error.response.data.error) == 'object' && 
          error.response.data.error.coupon[0].includes("invalid")){

            this.$message({
              type: "error",
              showClose: true,
              message: "Entered coupon doesn't exist.",
            });

          }else if(error.response.data.error.includes("doesn't support")){

            let subscriptionPath = window.location.origin + "/subscription";

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error 
              + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }
      
    },
    applyCustomShipping(){

      if(this.customShippingCharges){

        if(!this.selectedShipping){

          this.selectedOrder.payment.shipping_amount = this.customShippingCharges;
          
          this.selectedOrder.shipping.shipping_amount = this.customShippingCharges;

          this.selectedOrder.shipping.shipping_rule_id = ""

          this.selectedOrder.shipping.shipping_id = ""

        }else if(this.selectedShipping){

          this.selectedShipping = "";

          this.selectedOrder.payment.shipping_amount = this.customShippingCharges;

          this.selectedOrder.shipping.shipping_amount = this.customShippingCharges;

          this.selectedOrder.shipping.shipping_rule_id = ""

          this.selectedOrder.shipping.shipping_id = ""

        }

      }else{

        this.selectedOrder.payment.shipping_amount = 0;

      }

    },
    checkDate(){

      if(this.selectedOrder.order_date && this.selectedOrder.delivery_date){

        var order_date = new Date(this.selectedOrder.order_date);
        var deliver_date = new Date(this.selectedOrder.delivery_date);

        if(deliver_date < order_date){

          this.$notify({
            type: "warning",
            title: "Alert",
            message: "Can't set delivery date before order date.",
          });

          this.selectedOrder.delivery_date = "";

        }

      }

    },
    productSelectionMessage(){

      if(this.selectedOrder.items.length == 0){

        this.$notify({
          type: "info",
          title: "Product Not Selected",
          message: "At least one product must be selected first.",
        });

      }

    },
    orderSelection(order){

      this.tempOrder = _.cloneDeep(order);

    },
    handleTaxInput(){

      if(this.selectedOrder.payment.tax != this.tempOrder.payment.tax){

        if(this.selectedOrder.payment.tax.length == 0){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Tax percentage field is required.",
          });

          this.selectedOrder.payment.tax = this.tempOrder.payment.tax;

          return

        }

        let regx = new RegExp(/^[1-9][0-9]*$/);

        if(!regx.test(this.selectedOrder.payment.tax)){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Tax percentage can only be numeric."
          });

          this.selectedOrder.payment.tax = this.tempOrder.payment.tax;

          return

        }

        if(this.selectedOrder.payment.tax > 100){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Tax percentage cannot be more than 100.",
          });

          this.selectedOrder.payment.tax = this.tempOrder.payment.tax;

          return

        }

        if(this.selectedOrder.payment.tax < 0){

          this.$notify({
            type: "warning",
            title: "Alert",
            message: "Tax percentage cannot be less than 0.",
          });

          this.selectedOrder.payment.tax = this.tempOrder.payment.tax;

          return

        }

      }
      
    },
    async handleOrderStatusChange(order, index){

      if(!order.delivery_date && order.status == 'Delivered'){

        this.$notify({
          type: "info",
          title: "Delivery Date Missing",
          message: "Order delivery date must be provided.",
        });

        this.orders[index].status = this.tempOrder.status;

        return

      }

      MessageBox.confirm(
        "Confirm changes?", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {

          let formData = new FormData();

          formData.append("id", order.id);
          formData.append("status", order.status);

          try{
            let res = await this.$axios.post("/order/status-change", formData);
            if(res.data.status_code == "10256"){

              let order = res.data.order;

              order.items = order.items.map((item) => {

                item.product = JSON.parse(item.product);

                return item.product;

              });

              this.$notify({
                title: "Success",
                type: "success",
                message: res.data.message,
              });

              let updatedOrder = {
                i: index,
                updatedOrder: order,
              };

              this.$store.commit("orders_module/update_orders_list",updatedOrder);

              this.$store.dispatch('main_stats/fetchStatsRequest');

              this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);
              
              this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

              this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'});

              this.searchedProduct = ''

              this.searchProduct();

            }
          }catch(error){

            this.orders[index].status = this.tempOrder.status;

            if(error.response){

              if(error.response.data.error.includes("order status can be") ||
              error.response.data.error.includes("not possible")){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }

        }).catch(() => {

          this.orders[index].status = this.tempOrder.status;

          this.$notify({
            type: "info",
            title: "Changes Discarded",
            message: "Changes discarded successfully",
          });

          this.tempOrder = [];

        });

    },
    async changeAddImage(e){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){
    
        const file = e.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
          
          this.customer.image = file;
          this.customer.image_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false;

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6)
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

        }
    
      }

    },
    resetValidator(){
      
      this.customer.isOpen = false;

      this.$refs.orderCustomerFormObserver.reset();

    },
    handleFormat(field){
      
      if(field == 'f_name'){

        let fname = this.customer.first_name;
        fname = fname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        fname = fname.replace(/\s+/g, ' ');
        fname = fname.replace(/^\w/, (c) => c.toUpperCase());
        
        this.customer.first_name = fname;

      }else if(field == 'l_name'){

        let lname = this.customer.last_name;
        lname = lname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        lname = lname.replace(/\s+/g, ' ');
        lname = lname.replace(/^\w/, (c) => c.toUpperCase());

        this.customer.last_name = lname;

      }

    },
    async handleSelected(e){

      if(!e.isActive){

        this.$refs.customerMultiSelect.deactivate();

        Swal.fire({
          title: 'Note',
          text: "The selected customer's status is currently inactive. It will be automatically changed to active upon generating the order.",
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#15223D',
          cancelButtonColor: '#15223D',
          confirmButtonText: 'OK'
        }).then((result) => {

          if(result.isConfirmed){
            
          }

        }).catch(() => {})

      }

      if(e.address_line_2 != null){

        //shipping 
        this.shipping_address = e.address_line_1 + e.address_line_2;
        this.selectedOrder.shipping.shipping_first_name = e.first_name;
        this.selectedOrder.shipping.shipping_last_name = e.last_name;
        this.selectedOrder.shipping.shipping_email = e.email;
        this.selectedOrder.shipping.shipping_contact_no = e.mobile_no;
        //billing
        this.billing_address = e.address_line_1 + e.address_line_2;
        this.selectedOrder.billing.billing_first_name = e.first_name;
        this.selectedOrder.billing.billing_last_name = e.last_name;
        this.selectedOrder.billing.billing_email = e.email;
        this.selectedOrder.billing.billing_contact_no = e.mobile_no;

      }else{

        //shipping
        this.shipping_address = e.address_line_1;
        this.selectedOrder.shipping.shipping_first_name = e.first_name;
        this.selectedOrder.shipping.shipping_last_name = e.last_name;
        this.selectedOrder.shipping.shipping_email = e.email;
        this.selectedOrder.shipping.shipping_contact_no = e.mobile_no;
        //billing
        this.billing_address = e.address_line_1;
        this.selectedOrder.billing.billing_first_name = e.first_name;
        this.selectedOrder.billing.billing_last_name = e.last_name;
        this.selectedOrder.billing.billing_email = e.email;
        this.selectedOrder.billing.billing_contact_no = e.mobile_no;

      }

      this.selectedCountry = e.city.state.country;

      this.selectedFormState = e.city.state;

      this.loadRegions(e.city.id).then(()=>{

        this.selectedCity = e.city;

      })

    },
    openCustomerForm(){

      this.customer = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile_no: "",
        city: null,
        address_line_1: "",
        address_line_2: "",
        image: "",
        image_src: "",
        isLoading: false,
        isActive: true,
        isOpen: true,
        passwordShow:false,
      };

      this.selectedCountry = null;
      this.selectedState = null;
      this.selectedCity = null;
      this.selectedListCity = [];

      // this.countriesList.forEach((country)=>{

      //   if(this.settings.country.name == country.name){

      //     if(this.settings.country.phonecode.startsWith("+")){

      //       this.customer.mobile_no = this.settings.country.phonecode.replace(/-/g, '');

      //     }else{

      //       this.customer.mobile_no = "+" + (this.settings.country.phonecode).replace(/-/g, '');

      //     }

      //   }

      // });

      this.contactFieldTouched = false;
      
      this.$refs.orderCustomerFormObserver.reset();
      this.$refs.customerMultiSelect.toggle();

    },
    resetCustomerForm(){

      this.customer = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile_no: "",
        city: null,
        address_line_1: "",
        address_line_2: "",
        image: "",
        image_src: "",
        isLoading: false,
        isActive: true,
        isOpen: false,
        passwordShow:false,
      };

      // this.selectedCity = [];
      // this.selectedCountry = [];
      // this.selectedState = [];

      // this.selectedFormState = []

      this.contactFieldTouched = false;

      this.$refs.orderCustomerFormObserver.reset();

    },
    customerCustomLabel(customer){

      if(customer.id != ''){

        if(customer.isActive){

          return `${customer.first_name} ${customer.last_name} - active`

        }else{

          return `${customer.first_name} ${customer.last_name} - inactive`

        }

      }else{
        
        return 'Select Customer'

      }

    },
    async handleAddCustomer(){

      let { customer } = this;

      let formData = new FormData();

      formData.append("first_name", customer.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append("last_name", customer.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append("email", customer.email);
      formData.append("city", customer.city.id);
      formData.append("address_line_1", customer.address_line_1.replace(/\s+/g,' ').trim());

      if(customer.address_line_2){

        formData.append('address_line_2', customer.address_line_2.replace(/\s+/g,' ').trim());

      }

      formData.append("password", customer.password);
      formData.append("mobile_no", customer.mobile_no.replace(/\s/g,''));

      formData.append("isActive", customer.isActive ? 1 : 0);

      if(customer.image != ""){

        formData.append("image", customer.image);

      }

      this.customer.isLoading = true;
      try{

        let res = await this.$axios.post("/addCustomer", formData);
        if(res.data.status_code == "1018"){

          let customer = res.data.customer;
          customer.isActive = customer.isActive ? true : false;

          this.list_customer.unshift(customer);

          this.selectedCountry = customer.city.state.country
          this.selectedFormState = customer.city.state
          // this.selectedCity = customer.city
          this.selectedOrder.customer = customer;
          this.tempSelectedCity = customer.city


          if(customer.address_line_2 != null){

            this.shipping_address = customer.address_line_1 + customer.address_line_2;
            this.billing_address = customer.address_line_1 + customer.address_line_2;
            
          }else{

            this.shipping_address = customer.address_line_1;
            this.billing_address = customer.address_line_1;

          }
          
          this.customerFieldRequired = false;

          this.loadCustomers();

          this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'});

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });


          this.resetCustomerForm();

          this.handleSelected(customer);

        }

        this.customer.isLoading = false;

      }catch(error){

        this.resetCustomerForm();

        if(error.response){

          if(error.response.data.error.includes("aleardy exists")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });
          
        }

      }

    },
    generatePassword(form,length){

      if(form == 'sliderForm'){

        let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+|}{?><:~``}";
        let retVal = "";

        for(var i = 0, n = charset.length; i < length; ++i){

          retVal += charset.charAt(Math.floor(Math.random() * n));
          
        }

        this.$refs.passwordInput.value = retVal;
        this.customer.password = retVal;

        this.$refs.passwordInput.focus();

      }

    },
    previewOrder(order, i, field, e){

      if(field == 'input'){

        if((e.target.tagName == 'TD' || e.target.dataset.open == 'true') && !this.tourIsActive){

          if(order.id != -1){

            if(this.selectedOrder.id == -1){

              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                  type: "warning",
                }
              ).then(()=>{

                if(order.previewable == 0){

                  let subscriptionPath = window.location.origin + "/subscription";

                  Swal.fire({
                    title: "Max Orders/Month Quota Exhausted",
                    html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                    <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                    icon: "warning",
                    showCancelButton: false,
                    showCloseButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Got it"
                  }).then((result) => {

                    if(result.isConfirmed){

                    }

                  });

                  return

                }

                let index = this.orders.findIndex((order) => order.id == -1);

                if(index != -1){

                  this.orders.splice(index,1); 

                }

                if(!this.orderUpdate){

                  let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                  this.orders[tempIndex] = this.tempOrder;

                }

                this.selectedOrder = order;

                if(order.coupon_usage){

                  this.selectedOrder.coupon_code = order.coupon_usage.coupon.coupon_code;
                  this.coupon[0] = order.coupon_usage.coupon;
                  this.selectedOrder.discountedAmount =
                    order.coupon_usage.discounted_amount;
                  this.selectedOrder.isUpdate = true;

                }else{

                  this.coupon = [];

                }

                if(this.selectedOrder.order_details){

                  this.shipping_address = this.selectedOrder.order_details.shipping_address;
              
                  this.selectedCountry = this.selectedOrder.order_details.shipping_city.country;
                  this.selectedFormState = this.selectedOrder.order_details.shipping_city.state

                  this.tempSelectedCity = this.selectedOrder.order_details.shipping_city;

                  if(this.selectedOrder.order_details.isSameBilling == 1){

                    this.isSameBilling = true
                    this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                    this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                    this.billing_address = this.selectedOrder.order_details.billing_address;

                    this.selectedOrder.shipping = {
                      shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                      shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                      shipping_email: this.selectedOrder.order_details.shipping_email,
                      shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                    }

                    this.selectedOrder.billing = {
                      billing_first_name: this.selectedOrder.order_details.billing_first_name,
                      billing_last_name: this.selectedOrder.order_details.billing_last_name,
                      billing_email: this.selectedOrder.order_details.billing_email,
                      billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                    }

                  }else{

                    this.isSameBilling = false
                    this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                    this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                    this.billing_address = this.selectedOrder.order_details.billing_address;
                    
                    this.selectedOrder.shipping = {
                      shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                      shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                      shipping_email: this.selectedOrder.order_details.shipping_email,
                      shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                    }

                    this.selectedOrder.billing = {
                      billing_first_name: this.selectedOrder.order_details.billing_first_name,
                      billing_last_name: this.selectedOrder.order_details.billing_last_name,
                      billing_email: this.selectedOrder.order_details.billing_email,
                      billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                    }

                  }

                }

                this.selectedOrder.index = i;
                this.selectedOrder.total_products = this.calTotalProduct;
                this.selectedOrder.isTouched = false;

                this.checkedItems = []

                this.shippingContactFieldTouched = false
                this.billingContactFieldTouched = false

                this.tempOrder = _.cloneDeep(this.selectedOrder);

              }).catch(()=>{
                
              })

            }else{

              if(order.previewable == 0){

                let subscriptionPath = window.location.origin + "/subscription";

                Swal.fire({
                  title: "Max Orders/Month Quota Exhausted",
                  html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                  <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                  icon: "warning",
                  showCancelButton: false,
                  showCloseButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Got it"
                }).then((result) => {

                  if(result.isConfirmed){

                  }

                });

                return

              }

              let index = this.orders.findIndex((order) => order.id == -1);

              if(index != -1){

                this.orders.splice(index,1); 

              }

              if(!this.orderUpdate){

                let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                this.orders[tempIndex] = this.tempOrder;

              }

              this.selectedOrder = order;

              if(order.coupon_usage){

                this.selectedOrder.coupon_code = order.coupon_usage.coupon.coupon_code;
                this.coupon[0] = order.coupon_usage.coupon;
                this.selectedOrder.discountedAmount = order.coupon_usage.discounted_amount;
                this.selectedOrder.isUpdate = true;

              }else{

                this.coupon = [];

              }

              if(this.selectedOrder.order_details){

                this.shipping_address = this.selectedOrder.order_details.shipping_address;
                
                this.selectedCountry = this.selectedOrder.order_details.shipping_city.country;
                this.selectedFormState = this.selectedOrder.order_details.shipping_city.state

                this.tempSelectedCity = this.selectedOrder.order_details.shipping_city;

                if(this.selectedOrder.order_details.isSameBilling == 1){

                  this.isSameBilling = true
                  this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                  this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                  this.billing_address = this.selectedOrder.order_details.billing_address;

                  this.selectedOrder.shipping = {
                    shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                    shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                    shipping_email: this.selectedOrder.order_details.shipping_email,
                    shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                  }

                  this.selectedOrder.billing = {
                    billing_first_name: this.selectedOrder.order_details.billing_first_name,
                    billing_last_name: this.selectedOrder.order_details.billing_last_name,
                    billing_email: this.selectedOrder.order_details.billing_email,
                    billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                  }

                }else{

                  this.isSameBilling = false
                  this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                  this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                  this.billing_address = this.selectedOrder.order_details.billing_address;

                  this.selectedOrder.shipping = {
                    shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                    shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                    shipping_email: this.selectedOrder.order_details.shipping_email,
                    shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                  }

                  this.selectedOrder.billing = {
                    billing_first_name: this.selectedOrder.order_details.billing_first_name,
                    billing_last_name: this.selectedOrder.order_details.billing_last_name,
                    billing_email: this.selectedOrder.order_details.billing_email,
                    billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                  }

                }

              }

              this.selectedOrder.shipping.shipping_id = ''
              this.selectedOrder.shipping.shipping_rule_id = ''
              this.selectedOrder.shipping.shipping_amount = this.selectedOrder.payment.shipping_amount

              if(this.selectedOrder.payment.shipping_name == 'Manual'){

                this.customShippingCharges = this.selectedOrder.payment.shipping_amount

              }

              this.selectedOrder.index = i;
              this.selectedOrder.total_products = this.calTotalProduct;
              this.selectedOrder.isTouched = false;

              this.checkedItems = []

              this.shippingContactFieldTouched = false
              this.billingContactFieldTouched = false

              this.tempOrder = _.cloneDeep(order);

            }

          }

        }

      }else{

        if(!this.tourIsActive){

          if(order.id != -1){

            if(this.selectedOrder.id == -1){

              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                  type: "warning",
                }
              ).then(()=>{


                if(order.previewable == 0){

                  let subscriptionPath = window.location.origin + "/subscription";

                  Swal.fire({
                    title: "Max Orders/Month Quota Exhausted",
                    html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                    <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                    icon: "warning",
                    showCancelButton: false,
                    showCloseButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Got it"
                  }).then((result) => {

                    if(result.isConfirmed){

                    }

                  });

                  return

                }

                let index = this.orders.findIndex((order) => order.id == -1);

                if(index != -1){

                  this.orders.splice(index,1); 

                }

                if(!this.orderUpdate){

                  let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                  this.orders[tempIndex] = this.tempOrder;

                }

                this.selectedOrder = order;

                if(order.coupon_usage){

                  this.selectedOrder.coupon_code = order.coupon_usage.coupon.coupon_code;
                  this.coupon[0] = order.coupon_usage.coupon;
                  this.selectedOrder.discountedAmount = order.coupon_usage.discounted_amount;
                  this.selectedOrder.isUpdate = true;

                }else{

                  this.coupon = [];

                }

                if(this.selectedOrder.order_details){

                  this.shipping_address = this.selectedOrder.order_details.shipping_address;

                  this.selectedCountry = this.selectedOrder.order_details.shipping_city.country;
                  this.selectedFormState = this.selectedOrder.order_details.shipping_city.state

                  this.tempSelectedCity = this.selectedOrder.order_details.shipping_city;

                  if(this.selectedOrder.order_details.isSameBilling == 1){

                    this.isSameBilling = true
                    this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                    this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                    this.billing_address = this.selectedOrder.order_details.billing_address;

                    this.selectedOrder.shipping = {
                      shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                      shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                      shipping_email: this.selectedOrder.order_details.shipping_email,
                      shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                    }

                    this.selectedOrder.billing = {
                      billing_first_name: this.selectedOrder.order_details.billing_first_name,
                      billing_last_name: this.selectedOrder.order_details.billing_last_name,
                      billing_email: this.selectedOrder.order_details.billing_email,
                      billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                    }

                  }else{

                    this.isSameBilling = false
                    this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                    this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                    this.billing_address = this.selectedOrder.order_details.billing_address;

                    this.selectedOrder.shipping = {
                      shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                      shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                      shipping_email: this.selectedOrder.order_details.shipping_email,
                      shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                    }

                    this.selectedOrder.billing = {
                      billing_first_name: this.selectedOrder.order_details.billing_first_name,
                      billing_last_name: this.selectedOrder.order_details.billing_last_name,
                      billing_email: this.selectedOrder.order_details.billing_email,
                      billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                    }

                  }

                }

                this.selectedOrder.index = i;
                this.selectedOrder.total_products = this.calTotalProduct;
                this.selectedOrder.isTouched = false;

                this.checkedItems = []

                this.shippingContactFieldTouched = false
                this.billingContactFieldTouched = false

                this.tempOrder = _.cloneDeep(this.selectedOrder);

              }).catch(()=>{})

            }else{


              if(order.previewable == 0){

                let subscriptionPath = window.location.origin + "/subscription";

                Swal.fire({
                  title: "Max Orders/Month Quota Exhausted",
                  html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                  <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                  icon: "warning",
                  showCancelButton: false,
                  showCloseButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Got it"
                }).then((result) => {

                  if(result.isConfirmed){

                  }

                });

                return

              }

              let index = this.orders.findIndex((order) => order.id == -1);

              if(index != -1){

                this.orders.splice(index,1); 

              }

              if(!this.orderUpdate){

                let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                this.orders[tempIndex] = this.tempOrder;

              }

              this.selectedOrder = order;

              if(order.coupon_usage){

                this.selectedOrder.coupon_code = order.coupon_usage.coupon.coupon_code;
                this.coupon[0] = order.coupon_usage.coupon;
                this.selectedOrder.discountedAmount = order.coupon_usage.discounted_amount;
                this.selectedOrder.isUpdate = true;

              }else{

                this.coupon = [];

              }

              if(this.selectedOrder.order_details){

                this.shipping_address = this.selectedOrder.order_details.shipping_address;

                this.selectedCountry = this.selectedOrder.order_details.shipping_city.country;
                this.selectedFormState = this.selectedOrder.order_details.shipping_city.state

                this.tempSelectedCity = this.selectedOrder.order_details.shipping_city;

                if(this.selectedOrder.order_details.isSameBilling == 1){

                  this.isSameBilling = true
                  this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                  this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                  this.billing_address = this.selectedOrder.order_details.billing_address;

                  this.selectedOrder.shipping = {
                    shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                    shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                    shipping_email: this.selectedOrder.order_details.shipping_email,
                    shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                  }

                  this.selectedOrder.billing = {
                    billing_first_name: this.selectedOrder.order_details.billing_first_name,
                    billing_last_name: this.selectedOrder.order_details.billing_last_name,
                    billing_email: this.selectedOrder.order_details.billing_email,
                    billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                  }

                }else{

                  this.isSameBilling = false
                  this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
                  this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

                  this.billing_address = this.selectedOrder.order_details.billing_address;

                  this.selectedOrder.shipping = {
                    shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
                    shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
                    shipping_email: this.selectedOrder.order_details.shipping_email,
                    shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
                  }

                  this.selectedOrder.billing = {
                    billing_first_name: this.selectedOrder.order_details.billing_first_name,
                    billing_last_name: this.selectedOrder.order_details.billing_last_name,
                    billing_email: this.selectedOrder.order_details.billing_email,
                    billing_contact_no: this.selectedOrder.order_details.billing_contact_no
                  }

                }

              }

              this.selectedOrder.shipping.shipping_id = ''
              this.selectedOrder.shipping.shipping_rule_id = ''
              this.selectedOrder.shipping.shipping_amount = this.selectedOrder.payment.shipping_amount

              if(this.selectedOrder.payment.shipping_name == 'Manual'){
                
                this.customShippingCharges = this.selectedOrder.payment.shipping_amount

              }

              this.selectedOrder.index = i;
              this.selectedOrder.total_products = this.calTotalProduct;
              this.selectedOrder.isTouched = false;

              this.checkedItems = []

              this.shippingContactFieldTouched = false
              this.billingContactFieldTouched = false

              this.tempOrder = _.cloneDeep(this.selectedOrder);
              
            }

          }

        }

      }

    },
    previewSearchedOrder(order,i){

      let index = this.orders.findIndex((order) => order.id == -1);

      if(index != -1){

        this.orders.splice(index,1); 

      }

      if(!this.orderUpdate){

        let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

        this.orders[tempIndex] = this.tempOrder;

      }

      this.selectedOrder = order;

      if(order.coupon_usage){

        this.selectedOrder.coupon_code = order.coupon_usage.coupon.coupon_code;
        this.coupon[0] = order.coupon_usage.coupon;
        this.selectedOrder.discountedAmount = order.coupon_usage.discounted_amount;
        this.selectedOrder.isUpdate = true;

      }else{

        this.coupon = [];

      }

      if(this.selectedOrder.order_details){

        this.shipping_address = this.selectedOrder.order_details.shipping_address;

        this.selectedCountry = this.selectedOrder.order_details.shipping_city.country;
        this.selectedFormState = this.selectedOrder.order_details.shipping_city.state

        this.tempSelectedCity = this.selectedOrder.order_details.shipping_city;

        if(this.selectedOrder.order_details.isSameBilling == 1){

          this.isSameBilling = true;
          this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
          this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

          this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

          this.billing_address = this.selectedOrder.order_details.billing_address;

          this.selectedOrder.shipping = {
            shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
            shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
            shipping_email: this.selectedOrder.order_details.shipping_email,
            shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
          }

          this.selectedOrder.billing = {
            billing_first_name: this.selectedOrder.order_details.billing_first_name,
            billing_last_name: this.selectedOrder.order_details.billing_last_name,
            billing_email: this.selectedOrder.order_details.billing_email,
            billing_contact_no: this.selectedOrder.order_details.billing_contact_no
          }

        }else{

          this.isSameBilling = false;
          this.selectedBillingCountry = this.selectedOrder.order_details.billing_city.country;
          this.selectedBillingState = this.selectedOrder.order_details.billing_city.state;

          this.selectedBillingCity = this.selectedOrder.order_details.billing_city;

          this.billing_address = this.selectedOrder.order_details.billing_address;

          this.selectedOrder.shipping = {
            shipping_first_name: this.selectedOrder.order_details.shipping_first_name,
            shipping_last_name: this.selectedOrder.order_details.shipping_last_name,
            shipping_email: this.selectedOrder.order_details.shipping_email,
            shipping_contact_no: this.selectedOrder.order_details.shipping_contact_no
          }

          this.selectedOrder.billing = {
            billing_first_name: this.selectedOrder.order_details.billing_first_name,
            billing_last_name: this.selectedOrder.order_details.billing_last_name,
            billing_email: this.selectedOrder.order_details.billing_email,
            billing_contact_no: this.selectedOrder.order_details.billing_contact_no
          }

        }

      }
      
      this.selectedOrder.shipping.shipping_id = ''
      this.selectedOrder.shipping.shipping_rule_id = ''
      this.selectedOrder.shipping.shipping_amount = this.selectedOrder.payment.shipping_amount;

      this.selectedOrder.index = i;
      this.selectedOrder.total_products = this.calTotalProduct;
      this.selectedOrder.isTouched = false;
      this.tempOrder = _.cloneDeep(this.selectedOrder);

      this.checkedItems = [];

    },
    closePreview(){

      if(this.selectedOrder.id == -1 && this.selectedOrder.isTouched){

        MessageBox.confirm(
          "Do you want to close? Entered information will be discarded.",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(()=>{

          let index = this.orders.findIndex((order) => order.id == -1);

          if(index != -1){

            this.orders.splice(index,1); 

          }

          if(!this.orderUpdate){

            let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

            this.orders[tempIndex] = this.tempOrder;

          }

          this.coupon = [];
          this.selectedCustomer = null;
          this.tempOrderNote = "";
          this.tempOrderNotes = [];
          this.shippingOptions = [];
          this.selectedShipping = "";
          this.customShippingCharges = null;
          this.selectedOrder = this.form;
          this.fieldRequired = false;
          this.customerFieldRequired = false;
          this.hasProducts = false;
          this.shipping_address = "";
          this.billing_address = "";
          this.selectedCity = "";
          this.selectedCountry = null;
          this.selectedFormState = null

          this.isSameBilling = false
          this.selectedBillingCity = null
          this.listBillingCities = []
          this.selectedBillingCountry = null
          this.listBillingCountries = []
          this.selectedBillingState = null
          this.listBillingStates = []

          this.isMasterSearched = false

          this.orderUpdate = false

          this.list_customer = _.cloneDeep(this.tempCustomersList)

        }).catch(()=>{})
        
      }else{

        let index = this.orders.findIndex((order) => order.id == -1);

        if(index != -1){

          this.orders.splice(index,1); 

        }

        if(!this.orderUpdate){

          let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

          this.orders[tempIndex] = this.tempOrder;

        }

        this.coupon = [];
        this.selectedCustomer = null;
        this.tempOrderNote = "";
        this.tempOrderNotes = [];
        this.shippingOptions = [];
        this.selectedShipping = "";
        this.customShippingCharges = null;
        this.selectedOrder = this.form;
        this.fieldRequired = false;
        this.customerFieldRequired = false;
        this.hasProducts = false;
        this.shipping_address = "";
        this.billing_address = "";
        this.selectedCity = "";
        this.selectedCountry = null;
        this.selectedFormState = null

        this.isSameBilling = false
        this.selectedBillingCity = null
        this.listBillingCities = []
        this.selectedBillingCountry = null
        this.listBillingCountries = []
        this.selectedBillingState = null
        this.listBillingStates = []

        this.isMasterSearched = false

        this.orderUpdate = false

        this.list_customer = _.cloneDeep(this.tempCustomersList)

      }

    },
    resetPreview(){

      let index = this.orders.findIndex((order) => order.id == -1);

      if(index != -1){

        this.orders.splice(index,1); 

      }

      if(!this.orderUpdate){

        let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

        this.orders[tempIndex] = this.tempOrder;

      }

      this.coupon = [];
      this.selectedCustomer = null;
      this.tempOrderNote = "";
      this.tempOrderNotes = [];
      this.shippingOptions = [];
      this.selectedShipping = "";
      this.customShippingCharges = null;
      this.selectedOrder = this.form;
      this.fieldRequired = false;
      this.customerFieldRequired = false;
      this.hasProducts = false;
      this.shipping_address = "";
      this.billing_address = "";
      this.selectedCity = "";
      this.selectedCountry = null;
      this.selectedFormState = null

      this.isSameBilling = false
      this.selectedBillingCity = null
      this.listBillingCities = []
      this.selectedBillingCountry = null
      this.listBillingCountries = []
      this.selectedBillingState = null
      this.listBillingStates = []

      this.orderUpdate = false

      this.list_customer = _.cloneDeep(this.tempCustomersList)

    },
    openPreview(order,index){

      this.selectedOrder = order;
      if(order.coupon_usage){

        this.selectedOrder.coupon_code = order.coupon_usage.coupon.coupon_code;
        this.coupon[0] = order.coupon_usage.coupon;
        this.selectedOrder.discountedAmount = order.coupon_usage.discounted_amount;
        this.selectedOrder.isUpdate = true;

      }else{

        this.coupon = [];

      }
      if(this.selectedOrder.order_details){

        this.shipping_address = this.selectedOrder.order_details.shipping_address;
        this.billing_address = this.selectedOrder.order_details.billing_address;
        // let billingCity = {
        //   name: this.selectedOrder.order_details.billing_city,
        // };
        this.selectedCity = this.selectedOrder.order_details.billing_city;

      }
      
      this.payment_method_list.forEach(method => {

        if(method.type == "Cash on Delivery"){

          this.selectedOrder.payment.payment_method = method

        }

      });

      this.selectedOrder.index = index;
      this.selectedOrder.total_products = this.calTotalProduct;
      this.selectedOrder.isTouched = false;
      this.tempOrder = _.cloneDeep(this.selectedOrder);

      this.shippingContactFieldTouched = false

      this.checkedItems = []

    },
    expand(){

      this.$refs.viewInputForm.classList.toggle("fullWidthSlide");

    },
    keyEvent(e){

      if(e.key == 'Escape'){

        if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.$refs.viewInputForm.classList.remove('fullWidthSlide');

        }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

          this.closePreview();

        }

      }

    },
    searchOrder(searchedOrder){

      this.resetPreview();

      this.searchLoader = true;
      this.boxisLoading = true;

      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        if(searchedOrder.length > 0){

          let payload = {
            keyword: searchedOrder,
          };
          this.$store.commit("orders_module/update_searched_keyword", payload);
          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

        }else{

          this.$store.commit("orders_module/remove_searched_keyword");
          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

        }
      }, 500);

    },
    removeItem(item, form ,index){

      if(form == "productModal"){

        this.selectedOrder.items.splice(index, 1);

      }else if(form == "sliderForm"){

        MessageBox.confirm(
          "Do you really want to remove the item?", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(()=>{

          this.selectedOrder.items.splice(index, 1);

        }).catch(()=>{})

      }

    },
    quantityChange(action,index){

      if(action == 'incr'){

        if(this.selectedOrder.items[index].variant.qty >= this.selectedOrder.items[index].variant.stock){

          this.selectedOrder.items[index].variant.qty = this.selectedOrder.items[index].variant.stock;

        }else{

          this.selectedOrder.items[index].variant.qty++

        }

        if(this.selectedOrder.payment.selected_option != ""){

          this.$notify({
            type: "warning",
            title: "Shipping Option",
            message: "Selected shipping option has been removed.",
          });

          this.selectedOrder.payment.selected_option = ""
          this.selectedOrder.payment.shipping_name = ""
          this.selectedOrder.payment.package_name = ""

        }

        this.selectedShipping = ""
        this.selectedOrder.payment.shipping_amount = 0

        this.selectedOrder.shipping.shipping_rule_id = ''
        this.selectedOrder.shipping.shipping_amount = 0
        this.selectedOrder.shipping.shipping_id = ''

      }

      if(action == 'decr'){

        if(this.selectedOrder.items[index].variant.qty > 1){

          this.selectedOrder.items[index].variant.qty--

        }else{

          this.selectedOrder.items[index].variant.qty = this.selectedOrder.items[index].variant.qty;

        }

        if(this.selectedOrder.payment.selected_option != ""){

          this.$notify({
            type: "warning",
            title: "Shipping Option",
            message: "Selected shipping option has been removed.",
          });

          this.selectedOrder.payment.selected_option = ""
          this.selectedOrder.payment.shipping_name = ""
          this.selectedOrder.payment.package_name = ""

        }

        this.selectedShipping = ""
        this.selectedOrder.payment.shipping_amount = 0

        this.selectedOrder.shipping.shipping_rule_id = ''
        this.selectedOrder.shipping.shipping_amount = 0
        this.selectedOrder.shipping.shipping_id = ''

      }

    },
    quantityChangeCheck(index){

      if(this.selectedOrder.items[index].variant.stock < parseInt(this.selectedOrder.items[index].variant.qty)){
        
        this.$notify({
          type: "info",
          title: "Stock Limit Reached",
          message: "Cannot add more quantity than available stock.",
        });

        if(this.tempOrder.items.length > 0){

          this.selectedOrder.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

        }else{

          this.selectedOrder.items[index].variant.qty = this.selectedOrder.items[index].variant.stock;

        }

        return

      }else if(parseInt(this.selectedOrder.items[index].variant.qty) <= 0){

        this.$notify({
          type: "info",
          title: "Invalid Quantity",
          message: "Invalid quantity",
        });

        if(this.tempOrder.items.length > 0){

          this.selectedOrder.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

        }else{

          this.selectedOrder.items[index].variant.qty = this.selectedOrder.items[index].variant.stock;

        }

        return

      }else if(!Number.isInteger(this.selectedOrder.items[index].variant.qty)){
        
        this.$notify({
          type: "info",
          title: "Invalid Quantity",
          message: "Decimal values are not allowed for quantity.",
        });

        this.selectedOrder.items[index].variant.qty = parseInt(this.selectedOrder.items[index].variant.qty);

      }else{

        this.selectedOrder.items[index].variant.qty = parseInt(this.selectedOrder.items[index].variant.qty);

      }

    },
    addOrderNote(){

      if(this.tempOrderNote){

        let note = {
          note: this.tempOrderNote,
        };

        this.tempOrderNotes.push(note);

        this.$notify({
          type: "success",
          title: "Success",
          message: "Order note has been successfully added.",
        });

        this.tempOrderNote = "";

      }

    },
    orderNoteModalPreview(note, index){

      this.selectedOrderNote = note;
      this.orderNoteForm.updatedOrderNote = note.note;
      this.orderNoteForm.index = index;
      this.orderNoteForm.isOpen = true;
      
    },
    async updateOrderNote(){

      if(this.selectedOrderNote.created_at){

        let formData = new FormData();

        formData.append("order_note_id", this.selectedOrderNote.id);
        formData.append("order_id", this.selectedOrder.id);
        formData.append("new_note", this.orderNoteForm.updatedOrderNote);

        try{

          let res = await this.$axios.post("updateOrderNote", formData);
          if(res.data.status_code == "1231"){

            this.$notify({
              type: "success",
              title: "success",
              message: res.data.message,
            });

            let payload = {
              orderIndex: this.selectedOrder.index,
              updatedOrderNote: res.data.order_note,
              orderNoteIndex: this.orderNoteForm.index,
            };

            this.$store.commit("orders_module/update_OrderNote", payload);

            this.orderNoteForm.isOpen = false;

          }

        }catch(error){

          if(error.response){

            if(error.response.data.error.includes("deleted or isn't available.")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.message,
            });

          }

        }

      }else{

        this.orderNoteForm.isOpen = false;

        this.$notify({
          type: "success",
          title: "Success",
          message: "Order note has been successfully added.",
        });

        this.tempOrderNotes[this.orderNoteForm.index].note =
        this.orderNoteForm.updatedOrderNote;

      }

    },
    async removeOrderNote(note, index){

      MessageBox.confirm(
        "Are you sure you want to delete the order note?", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async ()=>{

        if(note.created_at){

          let formData = new FormData();

          formData.append("order_note_id", note.id);
          formData.append("order_id", this.selectedOrder.id);

          try{
            let res = await this.$axios.post("/removeOrderNote", formData);
            if(res.data.status_code == "1230"){

              this.$notify({
                type: "success",
                title: "Success",
                message: res.data.message,
              });

              let payload = {
                orderNote: note,
                noteIndex: index,
                orderIndex: this.selectedOrder.index,
              };

              this.$store.commit("orders_module/delete_ordersNote", payload);

              this.tempOrder.notes.splice(index,1);

            }

          }catch(error){

            if(error.response){

              if(error.response.data.error.includes("deleted or isn't available.")){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.message,
              });

            }

          }

        }else{

          this.$notify({
            type: "success",
            title: "Success",
            message: "Order note has been successfully removed.",
          });

          this.tempOrderNotes.splice(index, 1);

        }

      }).catch(()=>{});

    },
    createOrder(){

      if(this.boxisLoading){

        this.$notify({
          type: "warning",
          title: "Loading Data",
          message: "Please Wait",
        });

        return
      }
      
      let index = this.orders.findIndex((order) => order.id == -1);

      if(index != -1){

        this.orders.splice(index,1); 

      }
      
      if(!this.orderUpdate){

        this.orders[this.tempOrder.index] = this.tempOrder;

      }

      let form = {
        id: "",
        index: "",
        order_date: "",
        order_no: "",
        delivery_date: "",
        status: "",
        coupon_code: "",
        order_notes: "",
        total_products: "",
        shipping: {
        shipping_first_name:"",
        shipping_last_name:"",
        shipping_email:"",
        shipping_contact_no:"",
        shipping_id: '',
        shipping_rule_id: '',
        shipping_amount:0,
        },
        billing:{
          billing_first_name:"",
          billing_last_name:"",
          billing_email:"",
          billing_contact_no:"",
        },
        items: [],
        customer: {
          id: "",
          first_name: "",
          last_name: "",
          email: "",
          city: "",
          address_line_1: "",
          address_line_2: "",
          mobile_no: "",
        },
        total_weight: "",
        payment: {
          id: "",
          amount: "",
          net_amount: "",
          shipping_name: "",
          shipping_amount: 0,
          selected_option: "",
          status: "",
          tax: 0,
          payment_method: {
            id: "",
            type: "",
          },
        },
        activities: [],
        notes: [],
        discountedAmount: 0,
        isUpdate: false,
      };

      if(this.orders.length == 0){

        form.id = -1;
        form.order_no = `OC${1}`;

      }else{

        form.id = -1;
        form.order_no = `OC${this.orders.length + 1}`;
        // form.order_no = `OC${this.orders[this.orders.length - 1].order_no + 1}`;

      }
      
      if(this.settings){

        form.payment.tax = this.settings.tax;

      }

      this.selectedCustomer = null;
      this.orders.unshift(form);

      this.coupon = [];
      this.selectedCustomer = null;
      this.tempOrderNote = "";
      this.tempOrderNotes = [];
      this.shippingOptions = [];
      this.selectedShipping = "";
      this.customShippingCharges = null;
      this.selectedOrder = this.form;
      this.fieldRequired = false;
      this.customerFieldRequired = false;
      this.hasProducts = false;
      this.shipping_address = "";
      this.billing_address = "";
      this.selectedCity = "";
      this.selectedCountry = "";
      this.selectedFormState = "";
      this.selectedBillingCountry = "";
      this.selectedBillingState = "";
      this.selectedBillingCity = "";
      this.isSameBilling = true

      this.shippingContactFieldTouched = false
      this.billingContactFieldTouched = false


      if(this.$refs.orderTableObserver){

        this.$refs.orderTableObserver.reset();

      }

      this.openPreview(this.orders[0]);

    },
    async validate(action){

      if(action == "add"){

        this.$refs.orderTableObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.orderTableObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.orderTableObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

            if(this.selectedOrder.customer.id == ""){

              this.customerFieldRequired = true

            }
            if(this.selectedOrder.payment.payment_method.id == ""){

              this.fieldRequired = true

            }
            if(this.selectedOrder.items.length == 0){

              this.hasProducts = true

            }

            if(typeof this.shippingSelectedContactObj.valid == 'undefined'){

              this.shippingContactIsValid = false
              this.shippingContactFieldTouched = true

              document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(typeof this.billingSelectedContactObj.valid == 'undefined'){

              this.billingContactIsValid = false
              this.billingContactFieldTouched = true

              document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }


          }else{

            if(typeof this.shippingSelectedContactObj.valid == 'undefined'){

              this.shippingContactIsValid = false
              this.shippingContactFieldTouched = true

              document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(typeof this.billingSelectedContactObj.valid == 'undefined'){

              this.billingContactIsValid = false
              this.billingContactFieldTouched = true

              document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(this.shippingContactIsValid && this.shippingCountryIsSelected &&
            this.billingContactIsValid && this.billingCountryIsSelected){

              this.saveOrder();

            }else{
              
              if(!this.shippingContactIsValid || !this.shippingCountryIsSelected){

                document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }else if (!this.billingContactIsValid || !this.billingCountryIsSelected){

                document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }

            }

          }

        });

      }

      if(action == "update"){

        this.$refs.orderTableObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.orderTableObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.orderTableObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

            if(typeof this.shippingSelectedContactObj.valid == 'undefined'){

              this.shippingContactIsValid = false
              this.shippingContactFieldTouched = true

              document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(typeof this.billingSelectedContactObj.valid == 'undefined'){

              this.billingContactIsValid = false
              this.billingContactFieldTouched = true

              document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }else{

            if(this.shippingContactIsValid && this.shippingCountryIsSelected &&
            this.billingContactIsValid && this.billingCountryIsSelected){

              this.updateOrder();

            }else{
              
              if(!this.shippingContactIsValid || !this.shippingCountryIsSelected){

                document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }else if(!this.billingContactIsValid || !this.billingCountryIsSelected){

                document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }

            }

          }

        });

      }

      if(action == "addCustomer"){

        this.$refs.orderCustomerFormObserver.validate().then((success) => {

          if(!success){
            
            const errors = Object.entries(this.$refs.orderCustomerFormObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.orderCustomerFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

            if(typeof this.selectedContactObj.valid == 'undefined'){

              this.contactIsValid = false
              this.contactFieldTouched = true

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

            }else if(!this.selectedContactObj.valid){

              this.contactIsValid = false
              this.contactFieldTouched = true

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

            }

          }else{

            if(typeof this.selectedContactObj.valid == 'undefined'){

              this.contactIsValid = false
              this.contactFieldTouched = true

            }else if(!this.selectedContactObj.valid){

              this.contactIsValid = false
              this.contactFieldTouched = true

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

            }

            if(this.contactIsValid && this.countryIsSelected){

              this.handleAddCustomer();

            }else{

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }

        });

      }

    },
    async saveOrder(){

      if(this.selectedOrder.payment.payment_method.id == ""){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "Payment method must be selected.",
        });

        return (this.fieldRequired = true);

      }

      if(this.selectedOrder.customer.id == ''){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "Customer must be selected.",
        });

        this.customerFieldRequired = true

        return

      }

      if(this.selectedOrder.items.length == 0){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "At least one product must be selected.",
        });

        return (this.hasProducts = true);

      }

      if(this.selectedOrder.shipping.shipping_rule_id != ''){

        this.selectedOrder.shipping.shipping_id = this.shippingOptions.shipping_id

      }else{

        this.selectedOrder.shipping.shipping_id = ''

      }

      if(this.customShippingCharges){

        this.selectedOrder.payment.selected_option = "";
        this.selectedOrder.payment.shipping_amount = parseFloat(this.customShippingCharges).toFixed(2);
        this.selectedOrder.payment.shipping_name = "Manual";

        this.selectedOrder.shipping.shipping_rule_id = ''
        this.selectedOrder.shipping.shipping_amount = parseFloat(this.customShippingCharges).toFixed(2);
        this.selectedOrder.shipping.shipping_id = ''

        // let percent_amount =(this.selectedOrder.payment.tax / 100) * this.selectedOrder.payment.amount;

        // this.selectedOrder.payment.net_amount = ( parseFloat(this.selectedOrder.payment.amount) + parseFloat(this.selectedOrder.payment.shipping_amount) 
        //                                         + parseFloat(percent_amount)).toFixed(2);

        this.selectedOrder.payment.package_name = null;

      }

      let index = this.orders.indexOf(this.selectedOrder);

      let form = this.selectedOrder;
      form.payment.status = this.selectedOrder.status ? this.selectedOrder.status : "";
      form.shipping_amount = this.selectedOrder.payment.shipping_amount ? this.selectedOrder.payment.shipping_amount : 0;
      form.shipping_name = this.selectedOrder.payment.shipping_name ? this.selectedOrder.payment.shipping_name : "N/A";
      form.selected_option = this.selectedOrder.payment.selected_option;
      form.discounted_amount = this.selectedOrder.discountedAmount;

      if(this.tempOrderNotes.length > 0){

        form.notes = this.tempOrderNotes;

      }

      form.store_id = this.$store.state.user.store_id;

      form.customer.billing_address = this.billing_address;
      form.customer.shipping_address = this.shipping_address;
      form.customer.city = this.selectedCity.id;
      form.payment.net_amount = parseFloat(form.payment.net_amount)
      
      let formData = new FormData()

      //delivery information
      formData.append('order_date',form.order_date);
      formData.append('delivery_date',form.delivery_date);
      formData.append('status',form.status);
      formData.append('total_products',form.total_products);

      for(let i=0;i<form.items.length;i++){

        formData.append(`items[${i}][product_id]`,form.items[i].product_id);
        formData.append(`items[${i}][variant][id]`,form.items[i].variant.id);
        formData.append(`items[${i}][variant][qty]`,form.items[i].variant.qty);

      }

      //customer
      formData.append('customer[id]',form.customer.id);

      //shipping details
      formData.append('shipping[shipping_id]',form.shipping.shipping_id);
      formData.append('shipping[shipping_rule_id]',form.shipping.shipping_rule_id);
      formData.append('shipping[shipping_amount]',form.shipping_amount);
      formData.append('shipping[shipping_first_name]',form.shipping.shipping_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_last_name]',form.shipping.shipping_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_email]',form.shipping.shipping_email);
      formData.append('shipping[shipping_contact_no]',form.shipping.shipping_contact_no);
      formData.append('shipping[shipping_city]',form.customer.city)
      formData.append('shipping[shipping_address]',form.customer.shipping_address.replace(/\s+/g,' ').trim());
      
      formData.append('shipping[is_same_billing]',this.isSameBilling ? 1 : 0)
      
      //billing details
      if(!this.isSameBilling){

        formData.append('billing[billing_first_name]',form.billing.billing_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_last_name]',form.billing.billing_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_email]',form.billing.billing_email);
        formData.append('billing[billing_contact_no]',form.billing.billing_contact_no);
        formData.append('billing[billing_city]',this.selectedBillingCity.id)
        formData.append('billing[billing_address]',form.customer.billing_address.replace(/\s+/g,' ').trim());

      }

      //payment
      formData.append('payment[payment_method][id]',form.payment.payment_method.id);
      formData.append('payment[tax]',form.payment.tax);
      formData.append('payment[net_amount]',form.payment.net_amount);
      formData.append('payment[status]',form.payment.status);
      formData.append('payment[amount]',form.payment.amount);


      //coupon
      if(this.coupon.length > 0){

        formData.append('coupon_code',this.coupon[0].coupon_code);
        formData.append('discounted_amount',form.discounted_amount);

      }
      
      //admin notes
      if(form.notes.length > 0){

        for(let i=0;i<form.notes.length;i++){

          formData.append(`notes[${i}][note]`,form.notes[i].note);

        }

      }

      this.orderLoader = true;
      try{
        let res = await this.$axios.post("/createOrder", formData);
        if(res.data.status_code == "1023"){

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          this.$store.commit('orders_module/add_orders_list',res.data.order)

          this.$store.dispatch( "dashboard_stats/fetchStatsRequest", this.duration);

          this.$store.dispatch('main_stats/fetchStatsRequest');

          this.$store.dispatch("dashboard_order_stats/fetchStatsRequest");

          this.$store.commit('products_module/CLEAR_MODULE');

          this.$store.commit('customers_module/CLEAR_MODULE');

          this.resetPreview();

          this.searchedProduct = ''
          this.searchProduct()
          
        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.shipping_name){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.shipping_name[0],
            });

          }else if(error.response.data.error.shipping_amount){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.shipping_amount[0]
            });

          }else if(error.response.data.error.order_date){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.order_date[0],
            });

          }else if(error.response.data.error.status){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.status[0],
            });

          }else if(error.response.data.error.total_products){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.total_products[0],
            });

          }else if(error.response.data.error.payment){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.payment[0],
            });

          }else if(error.response.data.error.customer){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.customer[0],
            });

          }else if(error.response.data.error.includes('stock has run out') ||
          error.response.data.error.includes("doesn't support")){

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: "error",
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.orderLoader = false;

      }

    },
    async updateOrder(){

      if(this.tempOrder.status == "Cancelled"){

        this.$notify({
          type: "error",
          title: "Alert",
          message: "Cancelled order cannot be updated.",
        });

        return;

      }
      if(this.selectedOrder.payment.payment_method.id == ""){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "Payment method must be selected.",
        });

        return (this.fieldRequired = true);

      }
      if(this.selectedOrder.customer.id == ""){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "Customer must be selected.",
        });

        return (this.customerFieldRequired = true);

      }
      if(this.selectedOrder.items.length == 0){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "At least one product must be selected.",
        });

        return (this.hasProducts = true);

      }

      if(this.selectedOrder.shipping.shipping_rule_id != ''){

        this.selectedOrder.shipping.shipping_id = this.shippingOptions.shipping_id

      }else{

        this.selectedOrder.shipping.shipping_id = ''

      }


      if(this.customShippingCharges){

        this.selectedOrder.payment.selected_option = "";
        this.selectedOrder.payment.shipping_amount = parseFloat(this.customShippingCharges).toFixed(2);
        this.selectedOrder.payment.shipping_name = "Manual";

        this.selectedOrder.shipping.shipping_rule_id = ''
        this.selectedOrder.shipping.shipping_amount = parseFloat(this.customShippingCharges).toFixed(2);
        this.selectedOrder.shipping.shipping_id = ''

        // let percent_amount =
        //   (this.selectedOrder.payment.tax / 100) *
        //   this.selectedOrder.payment.amount;

        // this.selectedOrder.payment.net_amount = (
        //   parseFloat(this.selectedOrder.payment.amount) +
        //   parseFloat(this.selectedOrder.payment.shipping_amount) +
        //   parseFloat(percent_amount)
        // ).toFixed(2);

        this.selectedOrder.payment.package_name = null;

      }

      // if (this.selectedOrder.discountedAmount) {
      //   this.selectedOrder.payment.net_amount = (
      //     this.selectedOrder.payment.net_amount -
      //     parseFloat(this.selectedOrder.discountedAmount)
      //   ).toFixed(2);
      // }

      let index = this.selectedOrder.index;

      let form = this.selectedOrder;
      form.payment.status = this.selectedOrder.status
        ? this.selectedOrder.status
        : "pending";
      form.shipping_amount = this.selectedOrder.payment.shipping_amount
        ? this.selectedOrder.payment.shipping_amount
        : 0;
      form.shipping_name = this.selectedOrder.payment.shipping_name
        ? this.selectedOrder.payment.shipping_name
        : "N/A";
      form.selected_option = this.selectedOrder.payment.selected_option;
      form.discounted_amount = this.selectedOrder.discountedAmount;

      if(this.coupon.length > 0){

        form.coupon = this.coupon[0];

      }

      form.notes = [];

      if(this.tempOrderNotes.length > 0){

        form.notes = this.tempOrderNotes;

      }

      form.customer.billing_address = this.billing_address;
      form.customer.shipping_address = this.shipping_address;
      form.customer.city = this.selectedCity.id;

      let formData = new FormData();

      //delivery information
      formData.append('id',form.id);
      formData.append('order_date',form.order_date)
      formData.append('delivery_date',form.delivery_date)
      formData.append('order_no',form.order_no)
      formData.append('status',form.status)
      formData.append('total_products',form.total_products)

      for(let i=0;i<form.items.length;i++){

        formData.append(`items[${i}][product_id]`,form.items[i].product_id);
        formData.append(`items[${i}][variant][id]`,form.items[i].variant.id);
        formData.append(`items[${i}][variant][qty]`,form.items[i].variant.qty);

      }

      //customer
      formData.append('customer[id]',form.customer.id);

      //shipping details
      formData.append('shipping[shipping_id]',form.shipping.shipping_id);
      formData.append('shipping[shipping_rule_id]',form.shipping.shipping_rule_id);
      formData.append('shipping[shipping_amount]',form.shipping_amount);

      formData.append('shipping[shipping_first_name]',form.shipping.shipping_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_last_name]',form.shipping.shipping_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_email]',form.shipping.shipping_email);
      formData.append('shipping[shipping_contact_no]',form.shipping.shipping_contact_no);
      formData.append('shipping[shipping_city]',form.customer.city)
      formData.append('shipping[shipping_address]',form.customer.shipping_address.replace(/\s+/g,' ').trim());
      
      formData.append('shipping[is_same_billing]',this.isSameBilling ? 1 : 0)

      //billing details
      if(!this.isSameBilling){

        formData.append('billing[billing_first_name]',form.billing.billing_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_last_name]',form.billing.billing_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_email]',form.billing.billing_email);
        formData.append('billing[billing_contact_no]',form.billing.billing_contact_no);
        formData.append('billing[billing_city]',this.selectedBillingCity.id)
        formData.append('billing[billing_address]',form.customer.billing_address.replace(/\s+/g,' ').trim());

      }

      //payment
      formData.append('payment[payment_method][id]',form.payment.payment_method.id);
      formData.append('payment[tax]',form.payment.tax);
      formData.append('payment[net_amount]',form.payment.net_amount);
      formData.append('payment[status]',form.payment.status);
      formData.append('payment[amount]',form.payment.amount);

      //coupon
      if(this.coupon.length > 0){

        formData.append('coupon_code',this.coupon[0].coupon_code);
        formData.append('discounted_amount',form.discounted_amount);

      }

      //admin notes
      if(form.notes.length > 0){

        for(let i=0;i<form.notes.length;i++){

          formData.append(`notes[${i}][note]`,form.notes[i].note);

        }

      }

      if(form.coupon){

        formData.append('coupon[id]',form.coupon.id);

      }

      if(form.coupon_code){

        formData.append('coupon_code',form.coupon_code);

      }

      this.orderLoader = true;
      try{
        let res = await this.$axios.post("/updateOrder", formData);
        if(res.data.status_code == "1024"){

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          let order = res.data.order;

          order.items = order.items.map((item) => {

            item.product = JSON.parse(item.product);

            return item.product;

          });

          this.orders[index] = order;

          let updatedOrder = {
            i: index,
            updatedOrder: order,
          };

          this.$store.commit("orders_module/update_orders_list", updatedOrder);

          this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

          this.$store.dispatch('main_stats/fetchStatsRequest');

          this.$store.dispatch("dashboard_order_stats/fetchStatsRequest");

          this.$store.commit('products_module/CLEAR_MODULE');

          this.$store.commit('customers_module/CLEAR_MODULE');

          this.orderUpdate = true;

          this.resetPreview();

          this.searchedProduct = ''
          this.searchProduct()

        }

      }catch(error){

        if(error.response){

          if(error.response.data.error.includes('not possible') ||
          error.response.data.error.includes("deleted or isn't available.")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: "error",
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.orderLoader = false;

      }

    },
    async deleteOrder(order, index){

      if(order.id == -1){

        this.orders.splice(index, 1);

        return;

      }

      MessageBox.confirm(
        "Are you sure you want to delete the order?", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
       
        this.deletingItems.push(order.id);
        this.deleteLoader = true

        try{ 
          let res = await this.$axios.post("/deleteOrder", { id: order.id });
          if(res.data.status_code == "1025"){

            this.$notify({
              title: "Success",
              type: "success",
              message: res.data.message,
            });

            this.$store.commit("orders_module/delete_orders_list", index);

            this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

            this.$store.dispatch('main_stats/fetchStatsRequest');

            this.$store.commit('products_module/CLEAR_MODULE');

            this.$store.commit('customers_module/CLEAR_MODULE');

            this.deletingItems = [];
            this.deleteLoader = false

            this.searchedProduct = ''
            this.searchProduct()

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes('already') || 
            error.response.data.error.includes('not possible') ||
            error.response.data.error.includes("deleted or isn't available.")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          this.deletingItems = [];
          this.deleteLoader = false

        }

      }).catch(()=>{});

    },
    onTouch(){

      if(this.selectedOrder.payment.payment_method.id == ""){

        return (this.fieldRequired = true);
        
      }else{

        return (this.fieldRequired = false);

      }

    },
    onTouchCustomer(){

      if(this.selectedOrder.customer.id == ""){

        return (this.customerFieldRequired = true);

      }else{

        return (this.customerFieldRequired = false);

      }

    },
    checkedItemHandle(order){

      if(!this.tourIsActive && order.id != -1 && !this.selectedOrder.id){

        let index = this.checkedItems.findIndex(item => item == order.id);

        if(index != -1){

          this.checkedItems.splice(index,1)

        }else{

          this.checkedItems.push(order.id)
          
        }

      }

    },
    toggleIsCheckedAll(){

      if(!this.tourIsActive){

        if(this.checkedItems.length == this.orders.length){

          this.checkedItems = [];

        }else if(this.checkedItems.length < this.orders.length){

          this.checkedItems = [];

          this.orders.forEach((order) => {

            this.checkedItems.push(order.id);

          });

        }

      }

    },
    async applyAction(action){

      MessageBox.confirm(
        'Do you really want to make the changes?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then(async ()=>{

        if(action == "statusChange"){

          let isDeliveryDateMissing = false

          for(let i = 0; i <= this.checkedItems.length; i++){

            if((this.orders.some(order => order.id == this.checkedItems[i] && order.delivery_date == null)) && this.selectedStatus != 'Cancelled'){

              isDeliveryDateMissing = true
              break;

            }

          }

          if(isDeliveryDateMissing){

            this.$message({
              type: "error",
              showClose: true,
              message: 'The delivery date is missing for one or more of the selected orders.',
            });

            return

          }

          let formData = new FormData();

          formData.append("orders", JSON.stringify(this.checkedItems));
          formData.append("status", this.selectedStatus);
          formData.append("action", "statusChange");

          try{
            let res = await this.$axios.post("/bulkOrderAction", formData);
            if(res.data.status_code == "1024"){

              this.$notify({
                type: "success",
                title: "Success",
                message: res.data.message,
              });

              let payload = {
                action: 'statusChange',
                status: this.selectedStatus,
                listIds: this.checkedItems
              }

              this.$store.commit('orders_module/orders_bulk_action',payload)

              this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

              this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

              this.$store.dispatch('main_stats/fetchStatsRequest');

              this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'})

              this.checkedItems = [];
              this.selectedStatus = "";

              this.searchedProduct = ''
              this.searchProduct()
              
            }

          }catch(error){

            if(error.response){

              if(error.response.data.error.includes('delivery date is missing') || 
              error.response.data.error.includes("deleted or isn't available.")){
                
                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                }); 

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.message,
              }); 

            }

          }

        }else if(action == "bulkDelete"){

          let formData = new FormData();

          formData.append("orders", JSON.stringify(this.checkedItems));
          formData.append("status", this.selectedStatus);
          formData.append("action", "bulkDelete");  

          this.deletingItems = _.cloneDeep(this.checkedItems)
          this.deleteLoader = true;

          try{
            let res = await this.$axios.post("/bulkOrderAction", formData);
            if(res.data.status_code == "1024"){

              this.$notify({
                type: "success",
                title: "Success",
                message: res.data.message,
              });

              let payload = {
                action: 'delete',
                listIds: this.checkedItems
              }

              this.$store.commit('orders_module/orders_bulk_action',payload)

              this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

              this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

              this.$store.dispatch('main_stats/fetchStatsRequest');

              this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'})

              this.checkedItems = [];
              this.selectedStatus = "";
              this.deletingItems = [];
              this.deleteLoader = false;

              this.searchedProduct = ''
              this.searchProduct();
            }

          }catch(error){

            this.selectedStatus = "";
            this.deletingItems = [];
            this.deleteLoader = false;

            if(error.response){

              if(error.response.data.error.includes("deleted or isn't available.")){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                }); 

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }

        }

      }).catch(()=>{});

    },
    isDeletingItem(id){

      let find = this.deletingItems.find(x=>x == id);

      if(find){

        return true;

      }else{

        return false;

      }

    },
    calculateProductTotal(item){

      if(this.selectedOrder.id == -1){

        // let tempProductsTotal = 0;

        // this.selectedOrder.items.forEach((item) => {

        //   tempProductsTotal += item.variant.price * item.variant.qty;

        // });

        // return tempProductsTotal;

        return item.variant.price * item.variant.qty;

      }else{

        let tempProductsTotal = 0;

        // this.selectedOrder.items.forEach((item) => {

        //   // if(item.variant.price == item.variant.current_price){

        //     tempProductsTotal += item.variant.price * item.variant.qty;

        //   // }else{

        //   //   tempProductsTotal += item.variant.current_price * item.variant.qty;

        //   // }

        // });

        return item.variant.price * item.variant.qty;

      }

    },
    async loadRegions(city_id){

      this.regions = []
      this.shippingOptions = []

      try{
        let res = await this.$axios.get('/getAllRegions',{params:{city_id:city_id,}})
        if(res.data.status_code == "1011"){

          if(res.data.regions.length > 0){

            this.regions = res.data.regions

          }else{

            this.regions = []
            this.shippingOptions = []

          }

          return true

        }
      }catch(error){

        if(error.response){

          this.$message({
            type: "error",
            showClose: true,
            message: error.response.data.message,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });
          
        }

      }

    },
    async loadStates(form){

      let countryId = null
      if(form == 'shipping'){

        countryId = this.selectedCountry.id

      }else if(form == 'billing'){

        countryId = this.selectedBillingCountry.id

      }

      let res = await this.$axios.get('states/all',{params:{country_id:countryId}});
      if(res.data.status_code == "1010"){

        if(form == "shipping"){

          this.listStates = res.data.states

        }else if(form == "billing"){

          this.listBillingStates = res.data.states

        }

      }

    },
    async loadCities(form){

      let stateId = null

      if(form == 'shipping'){

        stateId = this.selectedState ? this.selectedState.id : this.selectedFormState.id

      }else if(form == 'billing'){

        stateId = this.selectedBillingState.id

      }


      let res = await this.$axios.get('cities/all',{params:{state_id:stateId}});
      if(res.data.status_code == "1010"){

        if(form == 'shipping'){

          this.listCities = res.data.cities

          if(this.tempSelectedCity != null){

            this.selectedCity = this.tempSelectedCity

            this.tempSelectedCity = null

          }
          
        }else if(form == 'billing'){

          this.listBillingCities = res.data.cities

        }

      }

    },
    //add customer contact methods starts here
    validateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.contactIsValid = contactObj.valid
        
      }

    },
    contactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.countryIsSelected = false

      }else if (typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.countryIsSelected = false

      }else if (typeof numberObj.country != 'undefined' && 
        typeof numberObj.valid == 'undefined' &&
        numberObj.formatted.length == numberObj.country.dialCode + 1){
        
        this.countryIsSelected = false

      }else{

        this.countryIsSelected = true
        
      }

      this.selectedContactObj = numberObj

    },
    dropDownCloseHandle(){

      if((this.customer.mobile_no).replace(/\s/g,'').length > this.selectedContactCountry.dialCode.length + 1){

        this.customer.mobile_no = "+" + this.selectedContactCountry.dialCode 

      }

      this.countryIsSelected = true

    },
    countryChangeHandle(countryObj){

      this.selectedContactCountry = countryObj

    },
    contactFieldFocusHandle(){

      this.contactFieldTouched = true

    },
    //add customer contact methods ends here
    //shipping contact methods starts here
    shippingValidateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.shippingContactIsValid = contactObj.valid

      }

    },
    shippingContactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.shippingCountryIsSelected = false

      }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.shippingCountryIsSelected = false

      }else if(typeof numberObj.country != 'undefined' && 
        typeof numberObj.valid == 'undefined' &&
        numberObj.formatted.length == numberObj.country.dialCode + 1){
        
        this.shippingCountryIsSelected = false

      }else{

        this.shippingCountryIsSelected = true
        
      }

      this.shippingSelectedContactObj = numberObj

      this.onChangeInField('contact_no');

    },
    shippingDropDownCloseHandle(){

      if((this.selectedOrder.shipping.shipping_contact_no).replace(/\s/g,'').length > this.shippingSelectedContactCountry.dialCode.length + 1){

        this.selectedOrder.shipping.shipping_contact_no = "+" + this.shippingSelectedContactCountry.dialCode 

      }

      this.shippingCountryIsSelected = true

    },
    shippingCountryChangeHandle(countryObj){

      this.shippingSelectedContactCountry = countryObj

    },
    shippingContactFieldFocusHandle(){

      this.shippingContactFieldTouched = true

    },
    //shipping contact methods ends here
    //billing contact methods starts here
    billingValidateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.billingContactIsValid = contactObj.valid

      }

    },
    billingContactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.billingCountryIsSelected = false

      }else if (typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.billingCountryIsSelected = false

      }else if (typeof numberObj.country != 'undefined' && 
        typeof numberObj.valid == 'undefined' &&
        numberObj.formatted.length == numberObj.country.dialCode + 1){
        
        this.billingCountryIsSelected = false

      }else{

        this.billingCountryIsSelected = true
        
      }

      this.billingSelectedContactObj = numberObj

      // this.onChangeInField('contact_no');

    },
    billingDropDownCloseHandle(){

      if((this.selectedOrder.billing.billing_contact_no).replace(/\s/g,'').length > this.billingSelectedContactCountry.dialCode.length + 1){

        this.selectedOrder.billing.billing_contact_no = "+" + this.billingSelectedContactCountry.dialCode 

      }

      this.billingCountryIsSelected = true

    },
    billingCountryChangeHandle(countryObj){

      this.billingSelectedContactCountry = countryObj

    },
    billingContactFieldFocusHandle(){

      this.billingContactFieldTouched = true

    },
    //billing contact methods ends here

    //load customers start here
    async loadCustomers(){

      try{
        let res = await this.$axios.get("/getAllCustomers");
        if(res.data.status_code == "1017"){

          this.list_customer = res.data.customers.map((val) => {

            return val;

          });

          this.tempCustomersList = _.cloneDeep(this.list_customer);

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }

    },
  },
  computed: {
    ...mapGetters({
      settings: "settings_module/settings",
      duration: "dashboard_stats/duration",
      orderStats: "order_stats/stats",
      storeOrders: "orders_module/storeOrders",
      storeOrdersCurrentPage: "orders_module/currentPage",
      storeOrdersLastPage: "orders_module/lastPage",
      isPending: "orders_module/isPending",
      storeAllCountries: 'countries_module/allCountries',
      orderSearchKeyword:'orders_module/searchedKeyword',
    }),
    calTotalProduct(){

      if(this.selectedOrder.id == -1){

        this.selectedOrder.total_products = this.selectedOrder.items.length;

        this.selectedOrder.payment.amount = 0.0;

        let weight = 0;

        this.selectedOrder.items.forEach((item) => {

          this.selectedOrder.payment.amount += item.variant.qty * item.variant.price;

          if(item.variant.weight){

            weight += item.variant.weight * item.variant.qty;

          }

        });

        this.selectedOrder.total_weight = parseFloat(weight).toFixed(2);

        let taxPercent = parseFloat(this.selectedOrder.payment.tax / 100).toFixed(2)
        let tempAmount = parseFloat(this.selectedOrder.payment.amount)

        let percent_amount = (taxPercent * tempAmount).toFixed(2);

        let tempPercentAmount= parseFloat(percent_amount)

        this.selectedOrder.payment.net_amount = (tempPercentAmount + this.selectedOrder.payment.amount).toFixed(2);

        if(this.selectedOrder.payment.shipping_amount){

          let tempShipping = parseFloat(this.selectedOrder.payment.shipping_amount);
          let tempNetAmount = parseFloat(this.selectedOrder.payment.net_amount);

          let tempAmount = (tempNetAmount + tempShipping).toFixed(2);

          this.selectedOrder.payment.net_amount = tempAmount;

        }

        if(this.coupon.length > 0){

          let discount = this.coupon[0].max_discount_value;
          let discountType = this.coupon[0].discount_type;

          if(discountType == "Flat"){

            if(discount >= this.selectedOrder.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.selectedOrder.discountedAmount = 0;

              this.selectedOrder.coupon_code = "";

            }else{

              this.selectedOrder.payment.net_amount = (this.selectedOrder.payment.net_amount - discount).toFixed(2);

              this.selectedOrder.discountedAmount = (discount).toFixed(2);

            }

          }else{

            let amount = (this.selectedOrder.payment.amount * (discount / 100)).toFixed(2);

            if(amount > this.selectedOrder.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.selectedOrder.discountedAmount = 0;

              this.selectedOrder.coupon_code = "";

            }else{

              this.selectedOrder.payment.net_amount = (this.selectedOrder.payment.net_amount - amount).toFixed(2);

              this.selectedOrder.discountedAmount = amount;

            }

          }

        }

        return this.selectedOrder.items.length;

      }else{

        this.selectedOrder.total_products = this.selectedOrder.items.length;

        this.selectedOrder.payment.amount = 0.0;

        let weight = 0;

        this.selectedOrder.items.forEach((item) => {

          // if(item.variant.price == item.variant.current_price){

            this.selectedOrder.payment.amount += item.variant.qty * item.variant.price;

            if(item.variant.weight){

              weight += item.variant.weight * item.variant.qty;

            }

          // }else{

          //   this.selectedOrder.payment.amount += item.variant.qty * item.variant.current_price;

          //   if(item.variant.weight){

          //     weight += item.variant.weight * item.variant.qty;

          //   }

          // }

        });

        this.selectedOrder.total_weight = parseFloat(weight).toFixed(2);

        let taxPercent = parseFloat(this.selectedOrder.payment.tax / 100).toFixed(2)
        let tempAmount = parseFloat(this.selectedOrder.payment.amount)

        let percent_amount = (taxPercent * tempAmount).toFixed(2);

        let tempPercentAmount= parseFloat(percent_amount)

        this.selectedOrder.payment.net_amount = (tempPercentAmount + this.selectedOrder.payment.amount).toFixed(2);

        if(this.selectedOrder.payment.shipping_amount){

          let tempShipping = parseFloat(this.selectedOrder.payment.shipping_amount);
          let tempNetAmount = parseFloat(this.selectedOrder.payment.net_amount);

          let tempAmount = (tempNetAmount + tempShipping).toFixed(2);

          this.selectedOrder.payment.net_amount = tempAmount;

        }

        if(this.coupon.length > 0){

          let discount = this.coupon[0].max_discount_value;
          let discountType = this.coupon[0].discount_type;

          if(discountType == "Flat"){

            if(discount > this.selectedOrder.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.selectedOrder.discountedAmount = 0;

              this.selectedOrder.coupon_code = "";

            }else{

              this.selectedOrder.payment.net_amount = (this.selectedOrder.payment.net_amount - discount).toFixed(2);

              this.selectedOrder.discountedAmount = (discount).toFixed(2);

            }

          }else{

            let amount = (this.selectedOrder.payment.amount * (discount / 100)).toFixed(2);

            if(amount > this.selectedOrder.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.selectedOrder.discountedAmount = 0;

              this.selectedOrder.coupon_code = "";

            }else{

              this.selectedOrder.payment.net_amount = (this.selectedOrder.payment.net_amount - amount).toFixed(2);

              this.selectedOrder.discountedAmount = amount;

            }

          }

        }

        return this.selectedOrder.items.length;

      }

      // let percent_amount = (this.selectedOrder.payment.tax / 100) * this.selectedOrder.payment.amount
      // this.selectedOrder.payment.net_amount = percent_amount + this.selectedOrder.payment.amount
      // if(this.selectedOrder.payment.shipping_amount){
      //     this.selectedOrder.payment.net_amount += parseInt(this.selectedOrder.payment.shipping_amount)
      // }


    },
    minDate(){

      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();

      if(month < 10){

        month = '0' + month.toString();

      }

      if(day < 10){

        day = '0' + day.toString();
        
      }

      var maxDate = year + '-' + month + '-' + day;

      return maxDate;

    },
  },
  watch: {
    selectedCity:{
      handler:function(value){
        
        if(value && this.selectedOrder.id != -1){

          this.loadRegions(value.id);

        }

        if(this.isSameBilling){

          this.selectedBillingCity = value

        }
        // let isFound = false;
        // let foundRegion = [];
        // this.shippingOptions = [];
        // this.selectedShipping = "";

        // if(this.regions.length > 0){
        //   isFound = true
        //   foundRegion = this.regions[0]
        // }

        // this.regions.forEach((region) => {
        //   region.related_cities.forEach((relatedCity) => {
        //     if (relatedCity.city.name == value.name) {
        //       isFound = true;
        //       foundRegion = region;
        //     }
        //   });
        // });
        // if (isFound) {
        //   let rules = JSON.parse(foundRegion.shipping.rules);
        //   if (foundRegion.shipping.type == "Flat Rate") {
        //     this.shippingOptions.rules = rules;
        //     this.shippingOptions.type = foundRegion.shipping.type;
        //   } else if (foundRegion.shipping.type == "Store Pickup Only") {
        //     this.shippingOptions.rules = rules;
        //     this.shippingOptions.type = foundRegion.shipping.type;
        //   } else if (foundRegion.shipping.type == "Rate By Price") {
        //     this.shippingOptions.rules = rules;
        //     this.shippingOptions.type = foundRegion.shipping.type;
        //   } else if (foundRegion.shipping.type == "Rate By Weight") {
        //     this.shippingOptions.rules = rules;
        //     this.shippingOptions.type = foundRegion.shipping.type;
        //   } else if (foundRegion.shipping.type == "Free Shipping") {
        //     this.shippingOptions.rules = rules;
        //     this.shippingOptions.type = foundRegion.shipping.type;

        //     this.selectedOrder.shipping.shipping_amount = 0;
        //     this.selectedOrder.shipping.shipping_rule_id = ''

        //     this.selectedOrder.payment.shipping_amount = 0;
        //     this.selectedOrder.payment.shipping_name =
        //       foundRegion.shipping.type;
        //   }
        //   this.selectedOrder.shipping.shipping_id = foundRegion.shipping_id

        //   //Preview Selection
        //   if (this.shippingOptions.type == "Rate By Weight") {
        //     this.shippingOptions.rules.forEach((rule) => {
        //       rule.weight.forEach((val) => {
        //         if (val.price == this.selectedOrder.payment.shipping_amount) {
        //           let obj = {
        //             shipping_name: this.shippingOptions.type,
        //             rule: rule,
        //           };
        //           this.selectedShipping = obj;
        //           this.selectedOrder.shipping.shipping_rule_id = rule.id
        //         }
        //       });
        //     });
        //   }
        //   if (this.shippingOptions.type == "Rate By Price") {
        //     this.shippingOptions.rules.forEach((rule) => {
        //       rule.prices.forEach((val) => {
        //         if (val.price == this.selectedOrder.payment.shipping_amount) {
        //           let obj = {
        //             shipping_name: this.shippingOptions.type,
        //             rule: rule,
        //           };
        //           this.selectedShipping = obj;
        //           this.selectedOrder.shipping.shipping_rule_id = rule.id

        //         }
        //       });
        //     });
        //   }
        //   if (this.shippingOptions.type == "Flat Rate") {
        //     this.shippingOptions.rules.forEach((rule) => {
        //       if (rule.price == this.selectedOrder.payment.shipping_amount) {
        //         this.selectedShipping = rule;
        //         this.selectedOrder.shipping.shipping_rule_id = rule.id
        //       }
        //     });
        //   }
        //   if (this.shippingOptions.type == "Store Pickup Only") {
        //     // this.selectedOrder.payment.shipping_amount = 0;
        //     // this.customShippingCharges = null
        //     let selectedOpt = null;

        //     if (this.selectedOrder.payment.selected_option) {
        //       if (
        //         typeof this.selectedOrder.payment.selected_option == "string"
        //       ) {
        //         selectedOpt = JSON.parse(
        //           this.selectedOrder.payment.selected_option
        //         );
        //       } else {
        //         selectedOpt = this.selectedOrder.payment.selected_option;
        //       }
        //     }

        //     this.shippingOptions.rules.forEach((rule) => {
        //       if (selectedOpt && rule.name == selectedOpt.name) {
        //         let obj = {
        //           shipping_name: this.shippingOptions.type,
        //           rule: rule,
        //         };
        //         this.selectedShipping = obj;
        //         this.selectedOrder.shipping.shipping_rule_id = rule.id
        //       }
        //     });
        //   }
        // }
        // if (this.selectedOrder.payment.shipping_name == "Manual") {
        //   this.customShippingCharges =
        //   this.selectedOrder.payment.shipping_amount;
        //   this.selectedOrder.shipping.shipping_id = ''
        //   this.selectedOrder.shipping.shipping_rule_id = ''
        //   this.selectedOrder.shipping.shipping_amount = this.customShippingCharges
        // }
      },
      deep: true,
    },
    regions:{
      handler:function(value){

        if(value.length > 0){

          let isFound = false;
          let foundRegion = [];
          this.shippingOptions = [];
          this.selectedShipping = "";

          isFound = true
          foundRegion = value[0]

          // this.regions.forEach((region) => {
          //   region.related_cities.forEach((relatedCity) => {
          //     if (relatedCity.city.name == value.name) {
          //       isFound = true;
          //       foundRegion = region;
          //     }
          //   });
          // });

          if(isFound){

            let rules = JSON.parse(foundRegion.shipping.rules);

            if(foundRegion.shipping.type == "Flat Rate"){

              this.shippingOptions.rules = rules;
              this.shippingOptions.type = foundRegion.shipping.type;
              this.shippingOptions.isNonCODDifferent = foundRegion.shipping.isNonCODDifferent
              this.shippingOptions.shipping_id = foundRegion.shipping_id

            }else if(foundRegion.shipping.type == "Store Pickup Only"){

              this.shippingOptions.rules = rules;
              this.shippingOptions.type = foundRegion.shipping.type;
              this.shippingOptions.shipping_id = foundRegion.shipping_id

            }else if(foundRegion.shipping.type == "Rate By Price"){

              this.shippingOptions.rules = rules;
              this.shippingOptions.type = foundRegion.shipping.type;
              this.shippingOptions.isNonCODDifferent = foundRegion.shipping.isNonCODDifferent
              this.shippingOptions.shipping_id = foundRegion.shipping_id

            }else if(foundRegion.shipping.type == "Rate By Weight"){

              this.shippingOptions.rules = rules;
              this.shippingOptions.type = foundRegion.shipping.type;
              this.shippingOptions.isNonCODDifferent = foundRegion.shipping.isNonCODDifferent
              this.shippingOptions.shipping_id = foundRegion.shipping_id

            }else if(foundRegion.shipping.type == "Free Shipping"){

              this.shippingOptions.rules = rules;
              this.shippingOptions.type = foundRegion.shipping.type;

              this.selectedOrder.shipping.shipping_amount = 0;
              this.selectedOrder.shipping.shipping_rule_id = ''

              this.selectedOrder.payment.shipping_amount = 0;
              this.selectedOrder.payment.shipping_name = foundRegion.shipping.type;
              this.shippingOptions.shipping_id = foundRegion.shipping_id

            }

            this.selectedOrder.shipping.shipping_id = foundRegion.shipping_id

            //Preview Selection
            if(this.shippingOptions.type == "Rate By Weight" && this.selectedOrder.payment.shipping_name != "Manual"){

              this.shippingOptions.rules.forEach((rule) => {

                rule.weight.forEach((val) => {

                  if(val.price == this.selectedOrder.payment.shipping_amount || val.non_cod_price == this.selectedOrder.payment.shipping_amount){

                    let obj = {
                      shipping_name: this.shippingOptions.type,
                      rule: rule,
                      // isNonCODDifferent: this.shippingOptions.isNonCODDifferent
                    };

                    this.selectedShipping = obj;
                    this.selectedOrder.shipping.shipping_rule_id = rule.id;

                  }

                });

              });

            }

            if(this.shippingOptions.type == "Rate By Price" && this.selectedOrder.payment.shipping_name != "Manual"){

              this.shippingOptions.rules.forEach((rule) => {

                rule.prices.forEach((val) => {

                  if(val.price == this.selectedOrder.payment.shipping_amount || val.non_cod_price == this.selectedOrder.payment.shipping_amount){

                    let obj = {
                      shipping_name: this.shippingOptions.type,
                      rule: rule,
                      // isNonCODDifferent: this.shippingOptions.isNonCODDifferent
                    };
                    this.selectedShipping = obj;
                    this.selectedOrder.shipping.shipping_rule_id = rule.id;

                  }

                });

              });

            }

            if(this.shippingOptions.type == "Flat Rate" && this.selectedOrder.payment.shipping_name != "Manual"){

              this.shippingOptions.rules.forEach((rule) => {

                if(rule.price == this.selectedOrder.payment.shipping_amount || rule.non_cod_price == this.selectedOrder.payment.shipping_amount){

                  this.selectedShipping = rule;
                  // this.selectedShipping.isNonCODDifferent = this.shippingOptions.isNonCODDifferent
                  this.selectedOrder.shipping.shipping_rule_id = rule.id;

                }

              });

            }

            if(this.shippingOptions.type == "Store Pickup Only" && this.selectedOrder.payment.shipping_name != "Manual"){
              // this.selectedOrder.payment.shipping_amount = 0;
              // this.customShippingCharges = null
              let selectedOpt = null;

              if(this.selectedOrder.payment.selected_option){

                if(typeof this.selectedOrder.payment.selected_option == "string"){

                  selectedOpt = JSON.parse(
                    this.selectedOrder.payment.selected_option
                  );

                }else{

                  selectedOpt = this.selectedOrder.payment.selected_option;

                }

              }

              this.shippingOptions.rules.forEach((rule) => {

                if(selectedOpt && rule.name == selectedOpt.name){

                  let obj = {
                    shipping_name: this.shippingOptions.type,
                    rule: rule,
                  };

                  this.selectedShipping = obj;
                  this.selectedOrder.shipping.shipping_rule_id = rule.id
                }

              });

            }

          }

          if(this.selectedOrder.payment.shipping_name == "Manual"){

            this.customShippingCharges = this.selectedOrder.payment.shipping_amount;
            this.selectedOrder.shipping.shipping_id = ''
            this.selectedOrder.shipping.shipping_rule_id = ''
            this.selectedOrder.shipping.shipping_amount = this.customShippingCharges;

          }

        }

      },deep:true
    },
    selectedShipping:{
      handler:function(value){

        if(value){

          if(value.price){

            this.customShippingCharges = null;
            if(this.selectedOrder.payment.payment_method.type != "Cash on Delivery" && this.shippingOptions.isNonCODDifferent){

              this.selectedOrder.payment.shipping_amount = parseInt(value.non_cod_price);

            }else{

              this.selectedOrder.payment.shipping_amount = parseInt(value.price);

            }

            this.selectedOrder.payment.shipping_name =
            this.shippingOptions.type;
            this.selectedOrder.payment.selected_option = value;
            this.selectedOrder.payment.package_name = value.name;

            this.selectedOrder.shipping.shipping_rule_id = value.id
            this.selectedOrder.shipping.shipping_amount = parseInt(value.price);

          }else if(value.rule.number){

            this.customShippingCharges = null;

            // this.selectedOrder.payment.shipping_name = value.shipping_name
            this.selectedOrder.payment.shipping_name = this.shippingOptions.type;

            this.selectedOrder.payment.selected_option = value.rule;
            this.selectedOrder.payment.package_name = value.rule.name;

            this.selectedOrder.shipping.shipping_rule_id = value.rule.id

          }else if(value.rule.prices){

            this.customShippingCharges = null;

            let amount = this.selectedOrder.payment.amount;
            let foundPrice = 0;
            let val = [];

            value.rule.prices.forEach((price) => {

              if (amount >= parseFloat(price.from).toFixed(2) && amount <= parseFloat(price.to).toFixed(2)){

                if(this.selectedOrder.payment.payment_method.type != "Cash on Delivery" && this.shippingOptions.isNonCODDifferent){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                }

                val = price;
              }else if(amount > parseFloat(price.from) && (price.to == "" || price.to == null)){

                if(this.selectedOrder.payment.payment_method.type != "Cash on Delivery" && this.shippingOptions.isNonCODDifferent){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                }

                val = price;

              }

            });

            this.selectedOrder.payment.shipping_amount = foundPrice;
            // this.selectedOrder.payment.shipping_name = value.shipping_name
            this.selectedOrder.payment.shipping_name = this.shippingOptions.type;

            this.selectedOrder.payment.selected_option = val;
            this.selectedOrder.payment.package_name = value.rule.name;

            this.selectedOrder.shipping.shipping_rule_id = value.rule.id
            this.selectedOrder.shipping.shipping_amount = foundPrice

          }else if(value.rule.weight){

            this.customShippingCharges = null;

            let amount = parseFloat(this.selectedOrder.total_weight).toFixed(2);

            let foundPrice = 0;
            let val = [];

            value.rule.weight.forEach((value) => {

              if((parseFloat(amount) >= parseFloat(value.from).toFixed(2) && parseFloat(amount) <= parseFloat(value.to).toFixed(2)) && (value.to != "" || value.to != null)){
                
                if(this.selectedOrder.payment.payment_method.type != "Cash on Delivery" && this.shippingOptions.isNonCODDifferent){

                  foundPrice = parseFloat(value.non_cod_price).toFixed(2);

                }else{

                  foundPrice = parseFloat(value.price).toFixed(2);

                }

                val = value;

              }else if(parseFloat(amount) > parseFloat(value.from).toFixed(2) &&(value.to == "" || value.to == null)){

                if(this.selectedOrder.payment.payment_method.type != "Cash on Delivery" && this.shippingOptions.isNonCODDifferent){

                  foundPrice = parseFloat(value.non_cod_price).toFixed(2);
                  
                }else{

                  foundPrice = parseFloat(value.price).toFixed(2);

                }

                val = value;
              }

            });

            this.selectedOrder.payment.shipping_amount = foundPrice;
            // this.selectedOrder.payment.shipping_name = value.shipping_name
            this.selectedOrder.payment.shipping_name = this.shippingOptions.type;

            this.selectedOrder.payment.selected_option = val;
            this.selectedOrder.payment.package_name = value.rule.name;

            this.selectedOrder.shipping.shipping_rule_id = value.rule.id
            this.selectedOrder.shipping.shipping_amount = foundPrice

          }else{

            this.selectedOrder.payment.shipping_amount = 0;
            this.selectedOrder.payment.shipping_name = null;
            this.selectedOrder.payment.selected_option = null;
            this.selectedOrder.payment.package_name = null;

            this.selectedOrder.shipping.shipping_id = ''
            this.selectedOrder.shipping.shipping_rule_id = ''
            this.selectedOrder.shipping.shipping_amount = ''

          }

        }

      },
      deep: true,
    },
    storeOrders:{
      handler:function(val){

        this.orders = val;
        this.boxisLoading = false;

        if(this.isCreateNew){

          this.createOrder();

          this.isCreateNew = false
        }

        if(this.isMasterSearched && this.selectedOrder.id == ''){

          this.previewSearchedOrder(this.orders[0],0)

          this.isMasterSearched = false

        }

      },
      deep: true,
    },
    coupon:{
      handler:function(val){
        // if(this.selectedOrder.isUpdate == false){
        if(val.length > 0){

          let discount = val[0].max_discount_value;
          let discountType = val[0].discount_type;

          if(discountType == "Flat"){

            this.selectedOrder.payment.net_amount = this.selectedOrder.payment.amount - discount;

            this.selectedOrder.discountedAmount = discount;

          }else{

            let amount = (this.selectedOrder.payment.amount * (discount / 100)).toFixed(2);

            this.selectedOrder.payment.net_amount = (this.selectedOrder.payment.amount - amount).toFixed(2);

            this.selectedOrder.discountedAmount = amount;

          }

        }
        // }
      },
      deep: true,
    },
    storeAllCountries:{
      handler:function(value){

        this.countriesList = value

        this.countriesList.forEach((country)=>{

          this.allowedCountries.push(country.iso2)

        });

      },deep:true
    },
    selectedOrder:{
      handler:function(value){

        if(value.order_date || value.delivery_date 
          || value.status || value.coupon_code || value.order_notes
          || value.total_products || value.shipping || value.items
          || value.customer.first_name || value.total_weight
          || value.payment.net_amount > 0 || value.activities.length > 0
          || value.notes.length > 0 || value.discountedAmount != 0)
        {

          value.isTouched = true;

        }else{

          value.isTouched = false;

        }

      },
      deep: true,
    },
    selectedCountry:{
      handler:function(value){

        if(value){

          this.listStates = [];
          this.selectedState = null;
          this.selectedListCity = null

          // this.regions = []
          this.shippingOptions = []

          if(this.isSameBilling){
            this.selectedBillingCountry = value
          }

          this.loadStates('shipping');

          // this.listStates = value.states
        }else{

          this.listStates = [];
          this.selectedState = null;
          this.selectedListCity = null

        }
        
      },deep:true
    },
    selectedState:{
      handler:function(value){

        if(value){

          this.listCities = [];
          this.selectedListCity = null

          // this.regions = []
          this.shippingOptions = []

          this.loadCities('shipping');

          // this.listCities = value.cities
        }else{

          this.listCities = [];
          this.selectedListCity = null;
          
        }

      },deep:true
    },
    selectedFormState:{
      handler:function(value){

        if(value && value.id){

          this.listCities = [];
          this.selectedCity = null

          // this.regions = []
          this.shippingOptions = []

          if(this.selectedOrder.id == -1){
            this.selectedOrder.discountedAmount = 0
            this.selectedOrder.shipping.shipping_amount = 0
            this.selectedOrder.payment.shipping_amount = 0
            this.selectedOrder.payment.selected_option = ""
            this.selectedOrder.payment.shipping_name = ""
          }

          if(this.isSameBilling){
            this.selectedBillingState = value
          }

          this.loadCities('shipping');

        }else{

          this.listCities = [];
          this.selectedCity = null;

        }

      },deep:true
    },
    selectedListCity:{
      handler:function(value){

        if(value){

          this.customer.city = value
          this.selectedCity = value

        }else{

          this.customer.city = null
          this.selectedCity = ''

        }

      },deep:true
    },

    // billing
    selectedBillingCountry:{
      handler:function(value){

        if(value){

          this.listBillingStates = []
          // this.selectedBillingState = null
          if(!this.isSameBilling){

            this.loadStates('billing');

          }

        }else{

          this.listBillingStates = []
          this.selectedBillingState = null

        }

      },deep:true
    },
    selectedBillingState:{
      handler:function(value){

        if(value){

          this.listBillingCities = [];
          // this.selectedBillingCity = null

          if(!this.isSameBilling){

            this.loadCities('billing');

          }

        }else{

          this.listBillingCities = [];
          // this.selectedBillingCity = null;

        }

      },deep:true
    }
  },
  mounted(){

    this.$refs.newOrdertable.addEventListener("scroll", this.handleScrollEvent);
    
    window.addEventListener("keydown", this.keyEvent);

    //add form top navigation scroll handle starts here
    const targetObserver = document.querySelector('#order-details-preview');

    const options = {
      root: targetObserver.parentElement, // Use the parent as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the target is visible
    };

    const intersectionCallback = (entries, observer) => {

      entries.forEach(entry => {

        if(entry.isIntersecting){

          if(entry.target.id == 'orderActivity' || entry.target.id == 'shipping-options-section' ||
          entry.target.id == 'orderActivitySection'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#order-activity-navbtn').classList.add('active');

          }else if(entry.target.id == 'customerDetail' || entry.target.id == 'customerSection'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#customer-details-navbtn').classList.add('active');

          }else if(entry.target.id == 'orderDetail' || entry.target.id == 'order-total-section'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#order-details-navbtn').classList.add('active');

          }else{

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

          }

        }

      });

    };

    const observer = new IntersectionObserver(intersectionCallback, options);

    const productTableInfoElements = document.querySelectorAll('.productTableInfoWhiteBox');

    productTableInfoElements.forEach(element => {

      observer.observe(element);

    });
    //add form top navigation scroll handle ends here

  },
  beforeDestroy(){

    window.removeEventListener("keydown", this.keyEvent);

  },
  async beforeMount(){
    
    try{
      if((!this.storeOrders || this.storeOrders.length == 0 || this.storeOrders.error)){

        if(this.$parent.$options.name && this.$parent.$options.name == 'orders-main'){

          if(this.orderSearchKeyword.length == 0){

            this.boxisLoading = true;

            this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

          }

        }else{

          this.boxisLoading = true;

          this.$store.commit("orders_module/remove_searched_keyword");

          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

        }

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true

        }

      }else{

        this.orders = this.storeOrders;
        this.boxisLoading = false;

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true

        }

        if(this.isCreateNew){

          this.createOrder();

          this.isCreateNew = false
        }

      }

      this.loadCustomers();

      if(!this.storeAllCountries || this.storeAllCountries.length == 0){

        this.$store.dispatch('countries_module/fetchAllCountries');

      }else{

        this.countriesList = this.storeAllCountries

        this.countriesList.forEach((country)=>{

          this.allowedCountries.push(country.iso2)

        });

      }

      let getProducts = await this.$axios.get("/getAllProducts");
      this.product_list = getProducts.data.products;

      let getPaymentMethod = await this.$axios.get("/getPaymentMethods",{
        params:{
          active: true
        }
      });

      this.payment_method_list = getPaymentMethod.data.payment_methods.map((pMethod) => {

        if(pMethod.type != 'Cash on Delivery'){

          pMethod.$isDisabled = true

        }

        return pMethod
      });

    }catch(error){

      this.boxisLoading = false;
      
      this.$message({
        type: "error",
        showClose: true,
        message: error.response ? error.response.data.message : error.message,
      });

    }

  },
};
window.myFunction = function (event) {

  if(!this.tourIsActive){

    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active")

      .forEach(function (item) {

        item.classList.remove("active");

      });

    // mark as active selected menu item
    event.target.classList.add("active");

  }

};
</script>
