<template>
  <div class="right-menu shipping-right">

    <subHeader pageName="ORDERS" />
    
    <tutorialsAction />

    <div class="db_top">
      <div class="db-container">

        <!-- module states component-->
        <moduleStats/>

        <div class="formOptionsBox">
          <div class="searchOptionsWrap">
            <div class="searchBlock" id="orders-search">
              <form @submit.prevent="">
                <div class="searchIconWrap">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                    <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  ref="searchInput"
                  placeholder="Search"
                  v-model="searchedOrder"
                  @input="filterOrders()"
                  :disabled="tourIsActive"
                />
              </form>
            </div>
            <div class="filterShowTabsRow" v-if="searchedOrder.length > 0">
              <div class="filterShowTabsListSearchedQuery">
                <p>Search results for "{{ searchedOrder.length > 20 ? searchedOrder.substr(0, 20) + "..." : searchedOrder }}".</p>
              </div>
              <div class="filterShowTabsResetBtn">
                <button @click.prevent="clearSearch">Clear</button>
              </div>
            </div>
          </div>
        </div>

        <!--order table component-->
        <OrderTable ref="order_table"/>

        <!-- new order modal -->
        <div class="modalBg" :class="form.isOpen ? 'modal-active' : ''">
          <div class="modalLayers" @click.prevent="form.isOpen = false"></div>
          <div class="modalBody" style="overflow-y: auto">
            <div id="London" class="" style="height: 80vh;overflow-y: auto;overflow-x: hidden;">
              <div class="contaient-div">
                <div class="customer-detail">
                  <h2>ORDER DETAILS</h2>
                </div>
                <table class="tab-table-1">
                  <tbody>
                    <tr>
                      <td>ID</td>
                      <td>#OC01</td>
                    </tr>
                    <tr>
                      <td>Order Date</td>
                      <td class="delivery-date">
                        <form>
                          <input
                            style="width: 100% !important"
                            class="form-control active"
                            type="date"
                          />
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>Delivery Date</td>
                      <td class="delivery-date">
                        <form>
                          <input
                            style="width: 100% !important"
                            class="form-control active"
                            type="date"
                          />
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Method</td>
                      <td><strong>Cash on Delivery</strong></td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td class="status">
                        <i class="fa fa-caret-down active-carrot" aria-hidden="true"></i>
                        <select class="active1" name="status" id="status">
                          <option value="status-0">Select</option>
                          <option value="status-1">Pending</option>
                          <option value="status-2">Deleiverd</option>
                          <option value="status-3">Cancelled</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Total Products</td>
                      <td class="active-product default">4</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td class="active-product default">Rs. 2,900</td>
                    </tr>
                    <tr>
                      <td>Tax</td>
                      <td>
                        <form class="incremnt-form">
                          <div class="value-button" id="decrease" value="Decrease Value">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </div>
                          <input type="number" id="number" value="0" />
                          <div class="value-button" id="increase" value="Increase Value">
                            <i class="fa fa-angle-up" aria-hidden="true"></i>
                          </div>
                          <div class="percent">
                            <i class="fa fa-caret-down active-carrot" aria-hidden="true"></i>
                            <select class="percent-select percent-select-active" name="status" id="status">
                              <option value="status-1">%</option>
                              <option value="status-2">&amp;</option>
                            </select>
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Fee</td>
                      <td class="active-product default">Rs. 100</td>
                    </tr>
                    <tr>
                      <td>
                        <button class="add-cost">
                          <span class="span-plus">+</span> ADD COST
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div class="td-form-show">
                  <form>
                    <div class="form-group custom-form">
                      <input
                        type="text"
                        name="text"
                        class="form-control"
                        placeholder="Cost Title"
                        required=""
                      />
                    </div>
                    <div class="form-group custom-form">
                      <input
                        type="text"
                        name="text"
                        class="form-control"
                        placeholder="Cost Amount"
                        required=""
                      />
                    </div>
                  </form>
                </div>
                <div class="amount-main">
                  <div class="amount">
                    <p>Net Total</p>
                    <p class="active">Rs. 3,232</p>
                  </div>
                </div>
              </div>

              <!-- Ordered Products -->
              <div class="contaient-div resp-container-product">
                <div class="customer-detail">
                  <h2>ORDERED PRODUCTS</h2>
                  <div class="">
                    <table class="product-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>NAME</th>
                          <th>TYPE</th>
                          <th>PRICE</th>
                          <th>QUANTITY</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img src="../../assets/images/blended@2x.png" />
                          </td>
                          <td>Portable Blender</td>
                          <td class="simple-td"><span>Simple</span></td>
                          <td>Rs. 18,00</td>
                          <td>
                            <div class="quantity">
                              <a href="#" class="quantity__minus"><span>-</span></a>
                              <input
                                name="quantity"
                                type="text"
                                class="quantity__input"
                                value="1"
                              />
                              <a href="#" class="quantity__plus"><span>+</span></a>
                            </div>
                          </td>
                          <td>
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../assets/images/blended@2x.png" />
                          </td>
                          <td>Portable Blender</td>
                          <td class="simple-td"><span>Simple</span></td>
                          <td>Rs. 18,00</td>
                          <td>
                            <div class="quantity">
                              <a href="#" class="quantity__minus"><span>-</span></a>
                              <input
                                name="quantity"
                                type="text"
                                class="quantity__input"
                                value="1"
                              />
                              <a href="#" class="quantity__plus"><span>+</span></a>
                            </div>
                          </td>
                          <td>
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <img src="../../assets/images/blended@2x.png" />
                          </td>
                          <td>Portable Blender</td>
                          <td class="variable-td"><span>Variable</span></td>
                          <td>Rs. 18,00</td>
                          <td>
                            <div class="quantity">
                              <a href="#" class="quantity__minus"><span>-</span></a>
                              <input
                                name="quantity"
                                type="text"
                                class="quantity__input"
                                value="1"
                              />
                              <a href="#" class="quantity__plus"><span>+</span></a>
                            </div>
                          </td>
                          <td>
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="../../assets/images/blended@2x.png" />
                          </td>
                          <td>Portable Blender</td>
                          <td class="variable-td"><span>Variable</span></td>
                          <td>Rs. 18,00</td>
                          <td>
                            <div class="quantity">
                              <a href="#" class="quantity__minus"><span>-</span></a>
                              <input
                                name="quantity"
                                type="text"
                                class="quantity__input"
                                value="1"
                              />
                              <a href="#" class="quantity__plus"><span>+</span></a>
                            </div>
                          </td>
                          <td>
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="assign-btn-1">
                      <button><span>+</span> ADD PRODUCT</button>
                    </div>

                    <div class="form-group mb-2 exclimatory order-plus">
                      <i class="fa fa-search" aria-hidden="true"></i>
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Delivery Option Name"
                        required=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- customer detail -->
              <div class="contaient-div">
                <div class="customer-detail">
                  <h2>CUSTOMER DETAILS</h2>
                  <div class="customer-profile">
                    <div class="img-row">
                      <img src="../../assets/images/customer-detail@2x.png" alt=""/>
                      <table class="t-customer">
                        <tbody>
                          <tr>
                            <td>ID</td>
                            <td>#CID01</td>
                          </tr>
                          <tr class="t-customer-name">
                            <td>Name</td>
                            <td>Ailya Hosein</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>aliyahosien@gmail.com</td>
                          </tr>
                          <tr>
                            <td>Contact</td>
                            <td>+92 323 3234 232</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- address-table -->
                    <div class="address-section">
                      <div class="row">
                        <div class="col-md-4 address-left-content">
                          Address
                        </div>
                        <div class="col-md-8 address-p">
                          <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy.
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 address-left-content">
                          Delivery Notes
                        </div>
                        <div class="col-md-8 address-p">
                          <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing
                            elitr, sed diam nonumy.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Assign Order -->
              <div class="contaient-div">
                <div class="customer-detail">
                  <h2>ASSIGN ORDER</h2>
                  <div class="assign-Order">
                    <!-- address-table -->
                    <div class="select-icon">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <select class="registration-select">
                        <option value="volvo">Select Role</option>
                        <option value="saab">Role-1</option>
                        <option value="opel">Role-2</option>
                        <option value="audi">Role-3</option>
                      </select>
                    </div>
                    <div class="assign-btn">
                      <button>ASSIGN</button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- COMMENTS HISTORY -->
              <div class="contaient-div">
                <div class="customer-detail">
                  <h2>COMMENTS HISTORY</h2>
                  <div class="row comment-history">
                    <div class="col-md-2">
                      <img src="../../assets/images/Subtraction-44@2x.png" />
                    </div>
                    <div class="col-md-10">
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing
                        elitr, sed diam nonumy eirmod aliquyam erat, sed diam
                        voluptua. At vero.
                      </p>
                    </div>
                  </div>
                  <div class="assign-btn">
                    <p>- ADMIN</p>
                  </div>
                  <div class="assign-Order">
                    <h2>ADD COMMENETS</h2>
                    <!-- address-table -->
                    <div class="form-group mb-2 custom-form create-section">
                      <textarea
                        class="form-control foam-border comment-box"
                        placeholder="Write Your Comment"
                        rows="3"
                        required=""
                        cols="50"
                        name="comment"
                        form="usrform"
                      ></textarea>
                    </div>
                    <div class="assign-btn">
                      <button>POST</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- NOTIFY CUSTOMERS -->
              <div class="contaient-div">
                <div class="customer-detail">
                  <h2>NOTIFY CUSTOMERS</h2>
                  <!-- address-table -->
                  <div class="form-group mb-2 custom-form create-section">
                    <textarea
                      class="form-control foam-border comment-box"
                      placeholder="Write Your Message (Optional)"
                      rows="3"
                      required=""
                      cols="50"
                      name="comment"
                      form="usrform"
                    ></textarea>
                  </div>
                  <div class="assign-btn">
                    <button>SEND</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modalCloseIcon">
              <button @click.prevent="form.isOpen = false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                  <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
      
    <!--vue tour-->
    <v-tour name="ordersTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import OrderTable from "@/components/OrderTable.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { MessageBox, Message } from "element-ui";
import tutorialsAction from "@/components/tutorialsAction.vue";

export default {
  name:'orders-main',
  metaInfo() {
    return {
      title: 'E-Commerce | Orders | YeetCommerce',
    };
  },
  data(){
    return{
      selectedOrder: null,
      selectedConversation: null,
      form: {
        isOpen: false,
      },
      customerName: null,
      searchedOrder:'',
      pIsLoading: false,
      isLoading:false,
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //search bar step
        {
          target: '#orders-search',
          content: `<strong>Search Order</strong><br>It enables you to search specific orders.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //form steps
        {
          target: '#preview-record',
          content: `<strong>Orders Table</strong><br>You can preview and edit the added orders by clicking on each individual record present in the table.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'top'
          }
        },
        {
          target: '#add-new-record',
          content: `Add an <strong>Order</strong><br>By clicking this button, you can add a new order and open a form to input and save the details.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#order-details-preview',
          content: `<strong>Order Details Form</strong><br>This form can be used to add details about the order and save them.`,
          params: {
            highlight: false,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#product-section',
          content: `<strong>Products Section</strong><br>Added products will be shown here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#add-product-btn',
          content: `<strong>Add Products</strong><br>Products can be added to the order by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#customer-section',
          content: `<strong>Customers Section</strong><br>Customer Details can be added/updated using this section.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'top'
          }
        },
        {
          target: '#add-customer-dropdown',
          content: `<strong>Customer Selection</strong><br>Customer can be added using this dropdown.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'top'
          }
        },
        {
          target: '#customer-basic-details',
          content: `<strong>Customer Details</strong><br>Basic information about customer will be shown here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#customer-shipping-details',
          content: `<strong>Customer Shipping Details</strong><br>Shipping details of customer can be added using this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#customer-billing-details',
          content: `<strong>Customer Billing Details</strong><br>Billing details of customer can be added using this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#same-billing-checkbox',
          content: `<strong>Same as Shipping</strong><br>Billing details will be considered same as the added shipping details if this box is checked.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#customer-order-note',
          content: `<strong>Customer Order Note</strong><br>Special note regarding added by customer regarding the order will be displayed here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#admin-order-notes',
          content: `<strong>Admin Order Notes</strong><br>You can add special notes for the order using this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#shipping-options-section',
          content: `<strong>Shipping Options Section</strong><br>Shipping options can be selected from available shipping options from this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#orderDetail',
          content: `<strong>Order Details Section</strong><br>Details regarding the order can be added from this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#order-total-section',
          content: `<strong>Order Total Section</strong><br>Order total break down can be seen from this section.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#custom-tax-btn',
          content: `<strong>Custom Tax</strong><br>You can add customized tax value for an order by clicking this option.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#expand-form',
          content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#closeSide',
          content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#save-order-btn',
          content: `<strong>Save Order Details</strong><br>Added order details can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
      ],
      tourIsActive:false
    }
  },
  components: {
    OrderTable,
    Multiselect,
    subHeader,
    moduleStats,
    tutorialsAction
  },
  methods: {
    createOrder(){

      this.$refs.order_table.createOrder();
      
    },
    filterOrders(){

      this.$refs.order_table.isMasterSearched = false;
      this.$refs.order_table.searchOrder(this.searchedOrder);
      
    },
    clearSearch(){

      this.searchedOrder = '';

      this.$refs.order_table.searchOrder(this.searchedOrder);

    },
    filterOrdersExternalSearch(){

      this.$refs.order_table.searchOrder(this.searchedOrder);

    },
    filterOrdersInternalSearch(preview){

      if(preview){

        this.$refs.order_table.isMasterSearched = true;

      }

      this.$refs.order_table.searchOrder(this.searchedOrder);

    },
    //tour methods
    startOrdersTour(){

      this.$tours.ordersTour.start();

      this.tourIsActive = true;

      this.$refs.order_table.tourIsActive = true;

      if(this.$refs.order_table.selectedOrder.id != ''){

        this.$refs.order_table.resetPreview();

      }

    },
    handleNextStep(currentStep){

      if(currentStep + 1 == 7){

        document.querySelector(".tableActionBtns").style.position = "static";
        document.querySelector(".productSlideBox").style.overflowY  = "hidden";

        this.$refs.order_table.createOrder();

      }

      if(currentStep + 1 == 23){

        if(!document.querySelector('.productSlideBox').classList.contains('fullWidthSlide')){

          this.$refs.order_table.expand();

        }

      }

      if(currentStep + 1 == 24){

        this.$refs.order_table.expand();

      }

    },
    handlePreviousStep(currentStep){

      if(currentStep == 7){

        this.$refs.order_table.resetPreview();

        document.querySelector(".productSlideBox").style.overflowY  = "auto";

      }

      if(currentStep <= 23){

        if(document.querySelector('.productSlideBox').classList.contains('fullWidthSlide')){

          this.$refs.order_table.expand();

        }

      }

      if(currentStep == 24){

        if(!document.querySelector('.productSlideBox').classList.contains('fullWidthSlide')){

          this.$refs.order_table.expand();

        }

      }

    },
    handleTourStop(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.$refs.order_table.resetPreview();

      this.tourIsActive = false;
        
      this.$refs.order_table.tourIsActive = false;

    },
    handleTourFinish(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.$refs.order_table.resetPreview();

      this.tourIsActive = false;

      this.$refs.order_table.tourIsActive = false;

    },
    handleTourSkip(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.$refs.order_table.resetPreview();

      this.tourIsActive = false;

      this.$refs.order_table.tourIsActive = false;

    },
  },
  computed:{
    ...mapGetters({
      isPending:'orders_module/isPending',
      logoutIsPending:'logoutIsPending',
      orderSearchKeyword:'orders_module/searchedKeyword',
    }),
  },
  watch:{

  },
  beforeRouteLeave(to, from, next){

    let index = null;
    this.$refs.order_table.orders.forEach((order) => {

      if(order.id == -1){

        index = this.$refs.order_table.orders.indexOf(order);

      }

    });

    if(window.innerWidth <= 1024){

      document.body.classList.remove('activeIpad');
      document.body.classList.add('hideSideBar');

    }

    if((this.$refs.order_table.selectedOrder.id || index != null) && to.fullPath != '/login'){

      MessageBox.confirm(
        'You may have unsaved changes, Proceeding will discard them',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Process',
          cancelButtonText: 'Stay',
        }
      ).then(()=>{

        this.$refs.order_table.resetPreview();
        next();

      }).catch(()=>{})

    }else{

      next();

    }
  },
  mounted(){

    if(this.$route.query && this.$route.query.search){

      this.searchedOrder = this.$route.query.search;

      if(this.$route.query.preview == 1){

        this.$refs.order_table.isMasterSearched = true;

      }

      this.$router.replace({'query': null});

      this.filterOrdersExternalSearch();

    }

    if(this.orderSearchKeyword.length > 0){

      this.searchedOrder = this.orderSearchKeyword;

    }
    
    window.addEventListener('beforeunload', (event) => {

      let index = null;
      this.$refs.order_table.orders.forEach((order) => {

        if(order.id == -1){

          index = this.$refs.order_table.orders.indexOf(order);

        }
        
      });
      
      if(this.form.isOpen || index != null){

        event.returnValue = "Are you sure you want to leave? entered information will be lost";

      }

    });

  },
  async beforeMount(){
  
  }
};
</script>

<style scoped>

  .ctmDashboardCardRow{
    display: flex;
    align-items: center;
    gap: 15.4px;
    flex-wrap: wrap;
  }
  .statesWrapBlock{
    width: 15.83%;
  }
  .statesWrapBlock p{
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .statesWrapBlock p span{
    display: inline-flex;
    align-items: center;
    margin-left: 5.5px;
  }
  .statesWrapBlock p span svg{
    display: inline-block;
    width: 8.511px;
    height: 9.726px;
    margin-right: 2.5px;
  }
  .statesWrapBlock p span svg:nth-last-child(1){
    margin-right: 0px;
  }
  .ctmDashboardCardItem{
    width: 100%;
    padding: 12px 17px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ctmDashboardCardItem.lightBlueCart{
    background-color: #1EC2C2;
  }
  .ctmDashboardCardItem.lightYellowCart{
    background-color: #E0E847;
  }
  .ctmDashboardCardItem.lightRedCart{
    background-color: #FD4D5D;
  }
  .orderStatesTxtBlock{
    width: 144px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .statesTxtField{
    text-align: center;
  }
  .statesTxtField h4{
    font-size: 30px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField p{
    font-size: 12px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.weekDetailTxt{
    opacity: 0.9;
  }
  .statesTxtField.weekDetailTxt h4{
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.weekDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.monthlyDetailTxt{
    opacity: 0.8;
  }
  .statesTxtField.monthlyDetailTxt h4{
    font-size: 19px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.monthlyDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .cardTxtWrap h4{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
  }
  .cardTxtWrap h4 span{
    display: inline-block;
    margin-bottom: 0px !important;
    color: #15223D;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: -5px;
    left: 3px
  }
  .cardTxtWrap p{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
  }
  .cardIconWrap{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: #fff;
    padding: 12px;
  }
  .lightBlueCart .cardIconWrap{
    background: #2CCBCB;
  }
  .lightYellowCart .cardIconWrap{
    background: #D4DC38;
  }
  .lightYellowCart .cardTxtWrap h4{
    color: #15223D;
  }
  .lightYellowCart .cardTxtWrap p{
    color: #15223D;
  }
  .lightRedCart .cardIconWrap{
    background: #EF3F4F;
  }
  .cardIconWrap img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

</style>